import React from "react";
import { AddBox } from '@mui/icons-material';
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { useHistory } from 'react-router-dom';
import { isUserAdmin } from "../actions/auth";


function Dashboard(props) {
  let history = useHistory();
  if (!isUserAdmin()) {
    history.push("/templates");
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3">
          <Link to="/builder">
            <Card on="true" className="card-chart">
              <CardHeader>
                <CardTitle tag="h3">
                  <AddBox />&nbsp;&nbsp;New Template
                </CardTitle>
              </CardHeader>
            </Card>
            </Link>
          </Col>
          <Col lg="3">
          <Link to="/user/create">
            <Card on="true" className="card-chart">
              <CardHeader>
                <CardTitle tag="h3">
                  <AddBox />&nbsp;&nbsp;New User
                </CardTitle>
              </CardHeader>
            </Card>
            </Link>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
