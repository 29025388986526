import React, { useEffect, useRef } from "react";
import Footer from "../components/Footer/Footer.js";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin.js";
import { useHistory } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { Button } from 'reactstrap';
import CoreSpinner from '../components/_core/CoreSpinner';
import PBCSDark from "../assets/img/pbcs_dark.png";
import PBCSLight from "../assets/img/pbcs_light.png";
import LogoDark from "../assets/img/SDP_dark.png";
import { ThemeContext } from '../contexts/ThemeContext';
import { useAuth0 } from "@auth0/auth0-react";
import { roles } from 'actions/auth';


const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: "10%",
    margin: "auto",
    width: "50%",
    padding: 10
  },
  Wrapper: {
    position: "relative",
    background: 'rgba(108, 133, 215, 0.4)'
  },
  logo: {
    marginTop: '30px',
    marginBottom: '10px',
    marginLeft: '40px',
    margin: "3%",
    width: "140px"
  },
  Text_SDP: {
    position: 'absolute',
    width: '30px',
    height: '16px',
    left: '183px',
    top: '38px',
    fontWeight: '500',
    fontSize: '25px',
    color: '#FFFFFF'
  },
  PBCS: {
    width: '304px',
    height: '30px',
    float: 'right',
    marginRight: '40px',
    marginTop: '20px',
    // fontFamily: 'Myriad Pro',
    fontStyle: 'normal',
    fontWeightt: '400',
    fontSize: '25px',
    color: '#2D76BC'
  },
  LoginButton: {
    padding: "14px 46px 14px 46px",
    fontSize: "15px!important",
    marginTop: '50px!important',
    width: '220px',
    marginLeft: '30%!important',
    height: '58.12px',
    background: 'white',
    color: "rgba(108, 133, 215, 1)",
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
  },
}));


function Login() {
  const mainPanelRef = useRef(null);
  require("../assets/scss/black-dashboard-react.scss");
  const theme = React.useContext(ThemeContext);
  const isDark = true;
  const classes = useStyles();
  let history = useHistory();

  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    error
  } = useAuth0();

  useEffect(() => {
    async function checkUser() {
      if (error) {
        return;
      }
      if (isAuthenticated) {
        switch (user.role) {
          case roles.superadmin:
            history.push("/companies");
            break;
          case roles.admin:
            history.push("/dashboard");
            break;
          default:
            history.push("/templates");
            break;
        }
      } else {
        loginWithRedirect();
      }
    }
    checkUser();
  }, [])


  return (
    <React.Fragment>
      <CoreSpinner loading={!error} />
      <div className={[classes.Wrapper, "wrapper"]}>
        <div className="main-panel" ref={mainPanelRef} data="green" style={{ background: 'rgba(108, 133, 215, 0.2)' }}>
          <img className={classes.logo} src={LogoDark} alt="SDP Logo" />
          <div className={classes.Text_SDP}>Secure Document Publisher</div>
          <img className={classes.PBCS} src={isDark ? PBCSDark : PBCSLight} alt="PoweredByClinicalSquared" />
          {/* <div className={classes.box}>
            <Button
              className={classes.LoginButton}
              variant="contained"
              onClick={() => loginWithRedirect()}
            >
              Log In
            </Button>
          </div> */}
          {
            error &&
            <div className={classes.box}>
              <h4 style={{ color: "red", paddingLeft: "25%"}}>{error.message}</h4>
              <Button
              className={classes.LoginButton}
              variant="contained"
              onClick={() => loginWithRedirect()}
            >
              Log In
            </Button>
            </div>
          }
          <div style={{ position: "absolute", bottom: 0, right: 0 }}>
            <Footer />
          </div>
        </div>
      </div>
      <FixedPlugin bgColor="green" />
    </React.Fragment>
  );
}

export default Login;
