import React, { useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import CoreButton from '../../components/_core/CoreButton';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
// import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import CoreSpinner from '../../components/_core/CoreSpinner';


const Print = (props) => {
    const componentRef = useRef();
    let history = useHistory();
    const [loading, setLoading] = useState();
    let data = null;
    let indexes = null;
    let patients = [];
    const environ = process.env.NODE_ENV;

    const styles = {
        button:
        {
            marginLeft: 15,
            backgroundColor: "#e24dc9",
            color: "white"
        }
    };

    if (environ === 'development') {
        const refreshDev = window.localStorage.getItem('refreshDev');
        if (!refreshDev) {
            window.location.reload();
            window.localStorage.setItem('refreshDev', true);
        };
    } else {
        const refreshProd = window.localStorage.getItem('refreshProd');
        if (refreshProd) {
            if (refreshProd === "/builder") {
                history.goBack();
            } else {
                history.push(refreshProd);
            };
        } else {
            document.querySelectorAll('link[rel="stylesheet"]').forEach(item => item.remove());
        };
    };

    function goBack() {
        if (environ === 'development') {
            history.goBack();
        } else {
            window.localStorage.setItem('refreshProd', "/builder");
            window.location.reload();
        };
    };

    try {
        data = props.location.state.data;
        indexes = props.location.state.indexes;

        data.patient.map((item, key) => {
            if (indexes.indexOf(key) > -1) {
                patients.push(data.patient[key]);
            };
            return null;
        })
    } catch {
        return (<Redirect to="/templates" />);
    };

    data.patient.reverse();

    function getDateTime() {
        var today = new Date();
        var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        var time = today.getHours() + 'h' + today.getMinutes() + 'm' + today.getSeconds() + "s";
        return `${date}_${time}`;
    };

    async function createPage(key, docName, folder, groupId) {
        const pdf = new jsPDF({
            orientation: 'p',
            format: 'government-letter'
        });
        pdf.internal.scaleFactor = 30;
        const comp = document.getElementById(`#page${key}`)
        const canvas = await html2canvas(comp);
        const imgData = canvas.toDataURL('image/png');
        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, 'PNG', 23, 15, width - 55, height - 25, undefined, "MEDIUM"); // LETTER
        const blob = pdf.output('blob');
        if (folder) {
            folder.file(docName, blob);
        } else {
            // await saveToCloud(docName, groupId, blob, true);
        };
    };

    async function toZip() {
        setLoading(true);
        const datetime = getDateTime();
        const folderName = "SDP_" + datetime + ".zip";
        const zip = require('jszip')();
        const folder = zip.folder(folderName);
        for (var i = 0; i < patients.length; i++) {
            let dName;
            try {
                dName = `${i+1}-${props.location.state.template.title.replaceAll(" ", "_")}`;
            } catch {
                dName = `${i+1}-New_Template`;
            };
            let docName = (patients[i].docName || dName) + ".pdf";
            await createPage(i, docName, folder);
        };
        const content = await zip.generateAsync({ type: "blob" });
        setLoading(false);
        saveAs(content, folderName);
    };

    const Items = () =>
        patients.map((item, key) => {
            let content = props.location.state.template.template;
            Object.keys(item).map((objKey, index) => {
                content = content.replaceAll(`{company.${objKey}}`, data.company[objKey]);
                content = content.replaceAll(`{${objKey}}`, patients[key][objKey]);
                const logo = props.location.state.template.logo;
                if (logo) {
                    const logoIndex = content.indexOf("{template logo");
                    const startIndex = logoIndex + 15;
                    let index = startIndex;
                    let char;
                    while (char !== "}" && (index - startIndex) < 4) {
                        char = content[index];
                        index++;
                    };
                    const size = content.slice(startIndex, index - 1);
                    content = content.replaceAll(`{template logo ${size}}`, `<img src=${logo} width="${size}%" alt="" />`);
                    content = content.replaceAll(`{template logo}`, `<img src=${logo} alt="" />`);
                }
                const logo2 = data.company.logo
                if (logo2) {
                    const logoIndex2 = content.indexOf("{company.logo");
                    const startIndex2 = logoIndex2 + 14;
                    let index2 = startIndex2;
                    let char2;
                    while (char2 !== "}" && (index2 - startIndex2) < 4) {
                        char2 = content[index2];
                        index2++;
                    };
                    const size2 = content.slice(startIndex2, index2 - 1);
                    content = content.replaceAll(`{company.logo ${size2}}`, `<img src=${logo2} width="${size2}%" alt="" />`);
                    content = content.replaceAll(`{company.logo}`, `<img src=${logo2} alt="" />`);
                }
                return content;
            })
            Object.keys(data.company).map((objKey, index) => {
                if (objKey !== "logo") {
                content = content.replaceAll(`{company.${objKey}}`, data.company[objKey]);
                };
                return content;
            })
            return (
                <>
                    <div style={{ marginLeft: "5%", marginRight: "5%", marginTop: "1%", marginBottom: "1%", pageBreakAfter: "always" }} key={key}>
                        <div id={`#page${key}`} style={{ height: 960 }} dangerouslySetInnerHTML={{ __html: content }} />
                        <div style={{ visibility: "hidden" }} id="noPrint"><hr /></div>
                    </div>
                </>
            );
        });

    return (
        <>
            <div style={{ overflow: "auto" }}>
                <div ref={componentRef} style={{ width: "660px", margin: "auto", height: "calc(100vh - 255px)" }}>
                    <Items />
                </div>
            </div>
            <div style={{ margin: "auto", width: "50%" }}>
                <CoreButton style={{ color: "#e24dc9" }} onClick={goBack} title="<< return" variant='text' />
                <ReactToPrint
                    content={() => componentRef.current}
                    trigger={() =>
                        <Button style={{ ...styles.button, margin: 20 }}>Print / Download</Button>
                    }
                />
                <Button style={{ ...styles.button, margin: 12 }} onClick={toZip}>Dowload separated files</Button>
                {/* {isAuth().business.docId &&
                    <Button style={styles.button} onClick={toCloud}><CloudUploadIcon style={{ marginRight: 8 }} />Upload to FS</Button>
                } */}
                {loading && <CoreSpinner loading={loading} />}
            </div>
        </>
    );
}

export default Print;