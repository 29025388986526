import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import SDPNavbar from "./components/Navbars/Navbar.js";
import Footer from "./components/Footer/Footer.js";
import Sidebar from "./components/Sidebar/Sidebar.js";
import FixedPlugin from "./components/FixedPlugin/FixedPlugin.js";
import routes from "./routes.js";
import Logo from "./assets/img/SDP.png";
import { isUserAdmin, isUserSuperadmin } from "./actions/auth";


var ps;

function Layout(props) {
  const redirect = () => {
    if (isUserSuperadmin()) {
      return "/companies";
    } else if (isUserAdmin()) {
      return "/dashboard";
    } else {
      return "/templates";
    };
  };
  require("./assets/scss/black-dashboard-react.scss");
  const location = useLocation();
  const mainPanelRef = React.useRef(null);
  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current, {
        suppressScrollX: true,
      });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);
  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      return (
        <Route
          path={prop.path}
          component={prop.component}
          key={key}
        />
      );
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].path) !== -1) {
        return routes[i].name;
      } else if (location.pathname.indexOf("/user/create") !== -1) {
        return "Create User";
      } else if (location.pathname.indexOf("/user/") !== -1) {
        return "";
      };
    };
    return "";
  };
  return (
    <React.Fragment>
      <div className="wrapper">
        <Sidebar
          routes={routes}
          logo={{
            outterLink: "",
            text: "SDP",
            imgSrc: Logo,
          }}
          toggleSidebar={toggleSidebar}
        />
        <div className="main-panel" ref={mainPanelRef} data="indigo">
          <SDPNavbar
            brandText={getBrandText(location.pathname)}
            toggleSidebar={toggleSidebar}
            sidebarOpened={sidebarOpened}
          />
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to={redirect()} />
          </Switch>
          <Footer fluid />
        </div>
      </div>
      <FixedPlugin />
    </React.Fragment>
  );
}

export default Layout;
