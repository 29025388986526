import { IconButton, Button } from '@mui/material';
import Modal from "react-bootstrap/Modal";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { isAuth, isUserAdmin, isUserSuperadmin } from '../../actions/auth';
import { useHistory } from 'react-router-dom';
import CoreSpinner from '../../components/_core/CoreSpinner';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditIcon from '@mui/icons-material/Edit';
import { get, post, put, deleteItem } from '../../actions/api';


const UserListItem = ({ user }) => {
    const [deleting, setDeleting] = useState(false);
    const [sending, setSending] = useState(false);
    const [modal, setModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    let history = useHistory();
    const id = isAuth().id;

    async function deleteItem() {
        setDeleting(true);
        history.go("/users");
    };

    return (
        <>
            <CoreSpinner loading={sending} />
            <Modal style={{ marginTop: 100 }} show={modal}>
                <Modal.Header>
                    <Modal.Title style={{ color: "red", marginBottom: 7 }}>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ marginBottom: -15 }}>
                    Are you sure you want to eliminate user:<br />
                    <strong>{user.firstName + " " + user.lastName}</strong>?<br /> <br />
                </Modal.Body>
                <Modal.Footer style={{ marginBottom: 25 }}>
                    {deleting && <CoreSpinner loading={deleting} />}
                    <Button style={{ marginLeft: "15px" }} color="primary" onClick={() => { setModal(false) }}>
                        Cancel
                    </Button>
                    <Button color="error" style={{ marginRight: "15px" }} onClick={deleteItem}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal style={{ marginTop: 100 }} show={errorModal}>
                <Modal.Header>
                    <Modal.Title style={{ color: "red", marginTop: -10 }}></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    You can't delete your own account!
                </Modal.Body>
                <Modal.Footer style={{ marginBottom: 25 }}>
                    <Button style={{ marginLeft: "15px" }} color="primary" onClick={() => { setErrorModal(false) }}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
            <tr>
                <td>{user.first_name + (user.id === id ?
                    "  (me)" : ""
                )}</td>
                <td>{user.title}</td>
                <td>{user.email}</td>
                {/* <td style={{ fontSize: 11, textAlign: "center" }}>{user.status}
                { isUserAdmin() || isUserSuperadmin() ?
                <>
                    {user.status === "FORCE_CHANGE_PASSWORD" &&
                        <>
                            {!emailSent ?
                                <IconButton style={{ fontSize: 11 }} onClick={sendEmail} color="primary">
                                    Resend Email
                                </IconButton>
                                :
                                <p style={{ fontSize: 11, color: "green", marginTop: 6 }}>Email sent!</p>
                            }
                        </>
                    }
                    </>
                    :
                    null
                    }
                </td> */}
                {
                    user.blocked !== undefined ?
                    <td style={{ textAlign: "center" }}>{user.blocked === false ? <CheckCircleOutlineIcon fontSize='medium' color='success' /> : <HighlightOffIcon fontSize='medium' color='error' />}</td>
                    :
                    <td style={{ textAlign: "center" }}>Not Registered</td>
                }
                
                <td style={{ textAlign: "center", textTransform: "capitalize" }}>{user.role}</td>

                {/* <td style={{ textAlign: "center", textTransform: "capitalize" }}>Tue,8/09/2022, 3:47:26 pm</td> */}
                {/* <td> */}
                {/* <IconButton onClick={() => { openModal() }} title="Delete" color='error'> */}
                {/* <Delete /> */}
                {/* <VisibilityOffIcon/> */}
                {/* </IconButton> */}
                {/* </td> */}
                {isUserAdmin() &&
                    <td>
                        <IconButton title="Edit" style={{ fontSize: 17 }} component={Link} to={`/user/${user.id}`} color="primary">
                            <EditIcon />
                        </IconButton>
                    </td>
                }
            </tr>
        </>
    );
};

export default UserListItem;