import React, { useState, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/material';
import { Button } from 'reactstrap';
import TextBox from '../../components/_core/Inputs/TextBox';
import { useHistory } from 'react-router-dom';
import Logo from "../../assets/img/SDP_dark.png";
import { IconButton, Grid } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { CognitoUser } from "amazon-cognito-identity-js";
import CoreSpinner from '../../components/_core/CoreSpinner';


const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: 'auto',
  },
  loginTitle: {
    textAlign: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  box: {
    marginTop: "2%",
    margin: "auto",
    width: "50%",
    padding: 10
  },
  logo: {
    margin: "3%",
    width: "140px"
  }
}));

const Forgot = () => {
  const mainPanelRef = useRef(null);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const [stage, setStage] = useState(1);
  const [error, setError] = useState(false);
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passPolicy, setPassPolicy] = useState({
    length: false,
    numbers: false,
    specialChars: false,
    uppercase: false,
    lowercase: false
  });
  require("../../assets/scss/black-dashboard-react.scss");

  const handleEmailChange = (evt) => {
    setEmail(evt.target.value);
  };

  // const getUser = () => {
  //   return new CognitoUser({
  //     Username: email.toLowerCase(),
  //     Pool: UserPool
  //   });
  // };

  function checkPassword(pass) {
    const special = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    setPassPolicy({
      length: pass.length >= 8 ? true : false,
      numbers: /\d/.test(pass) ? true : false,
      specialChars: special.test(pass) ? true : false,
      uppercase: /[A-Z]/.test(pass) ? true : false,
      lowercase: /[a-z]/.test(pass) ? true : false
    });
  };

  const sendCode = event => {
    event.preventDefault();
    setLoading(true);
    // getUser().forgotPassword({
    //   onSuccess: data => {
    //     console.log("onSuccess:", data);
    //     setLoading(false);
    //   },
    //   onFailure: err => {
    //     console.error("onFailure:", err);
    //     setLoading(false);
    //     setError(err.toString().split(":").pop());
    //   },
    //   inputVerificationCode: data => {
    //     console.log("Input code:", data);
    //     setLoading(false);
    //     setStage(2);
    //   },
    // });
  };

  const handlePasswordChange = (evt) => {
    setPassword(evt.target.value);
    checkPassword(evt.target.value);
  };

  const resetPassword = event => {
    event.preventDefault();
    setLoading(true);

    // getUser().confirmPassword(code, password, {
    //   onSuccess: data => {
    //     console.log("onSuccess:", data);
    //     setStage(3);
    //     setLoading(false);
    //   },
    //   onFailure: err => {
    //     console.error("onFailure:", err);
    //     setLoading(false);
    //     setError(err.toString().split(":").pop());
    //   },
    // });
  };

  return (
    <React.Fragment>
      {
        loading && <CoreSpinner loading={loading} />
      }
      <div style={{ position: "relative" }} className="wrapper">
        <div className="main-panel" ref={mainPanelRef} data="green">
          <img className={classes.logo} src={Logo} alt="SDP Logo" />
          <div className={classes.box}>
            <Container component="main" maxWidth="xs">
              <IconButton style={{ color: "#e14eca" }} onClick={() => { history.push("/login") }}>
                <ArrowBack />
              </IconButton>
              <h3 style={{ textAlign: "center" }}>{
                stage === 1 ? "Reset Password" :
                  stage === 2 ? "Check your email" :
                    "Done!"
              }</h3>
              {error && <><label style={{ color: "red", marginTop: 8 }}>{error}</label><br /></>}
              {!error && <>
                {stage === 1 &&
                  <form className={classes.form} onSubmit={sendCode}>
                    <TextBox
                      required
                      id="email"
                      label="Email Address"
                      name="email"
                      inputProps={{ maxLength: 70 }}
                      autoComplete="email"
                      autoFocus
                      onChange={handleEmailChange}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={!email || !(email.indexOf('@') > -1) || !(email.indexOf('.') > -1)}
                    >
                      Send verification code
                    </Button>
                  </form>
                }
                {stage === 2 &&
                  <div style={{ textAlign: "center" }}>
                    <p style={{ marginTop: 8, marginBottom: 15, color: "orange" }}>An email with a verification code has been sent to reset your password</p>
                    <form className={classes.form} onSubmit={resetPassword}>
                      <TextBox
                        required
                        value={code}
                        onChange={event => setCode(event.target.value)}
                        label="Code"
                        type="text"
                        autocomplete="off"
                        inputProps={{ maxLength: 10 }}
                      />
                      <TextBox
                        required
                        value={password}
                        onChange={handlePasswordChange}
                        label="Password"
                        type="password"
                        inputProps={{ maxLength: 20 }}
                      />
                      <TextBox
                        required
                        type="password"
                        value={confirmPassword}
                        onChange={event => setConfirmPassword(event.target.value)}
                        label="Confirm Password"
                        inputProps={{ maxLength: 20 }}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={!password || !confirmPassword || password !== confirmPassword || !Object.values(passPolicy).every(value => value)}
                      >
                        Change Password
                      </Button>
                      <Grid style={{ marginTop: 12, textAlign: "left" }} container>
                        <Grid className={classes.policy} item xs={6}>
                          <p>Password must at least contain:</p>
                          <p style={{ color: passPolicy.length ? "green" : "red" }}>- 8 characters</p>
                          <p style={{ color: passPolicy.numbers ? "green" : "red" }}>- a number</p>
                          <p style={{ color: passPolicy.specialChars ? "green" : "red" }}>- a special character</p>
                          <p style={{ color: passPolicy.uppercase ? "green" : "red" }}>- an uppercase letter</p>
                          <p style={{ color: passPolicy.lowercase ? "green" : "red" }}>- a lowercase letter</p>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                }
                {stage === 3 &&
                  <>
                    <p style={{ marginTop: 8, marginBottom: 15, color: "green" }}>Password has been reset!</p>
                    <Button
                      style={{ marginTop: 8 }}
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={() => { history.push("/login") }}
                    >
                      LOG IN
                    </Button>
                  </>
                }
              </>
              }
              {error &&
                <Button
                  style={{ marginTop: 8 }}
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => { window.location.reload() }}
                >
                  Try again
                </Button>
              }
            </Container>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
};

export default Forgot;