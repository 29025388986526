import { useEffect } from "react";
import { logout as LogOut } from '../../actions/auth';
import { useAuth0 } from "@auth0/auth0-react";


function Logout() {
  const {
    logout,
  } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      }
    });

  useEffect(() => {
    async function logOut() {
      await LogOut();
      logoutWithRedirect();
    }
    logOut();
  });
  return null;
}

export default Logout;
