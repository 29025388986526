import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import CoreSpinner from '../../components/_core/CoreSpinner';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { convertBase64, resizeFile } from '../../actions/helpers';
import { get, post, put, deleteItem } from '../../actions/api';


function CreateBusiness(props) {
  const [error, setError] = useState(null);
  const [fields, setFields] = useState({});
  const [notSaved, setNotSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imgError, setImgError] = useState(null);
  const [image, setImage] = useState(null);
  let history = useHistory();

  async function handleImageChange(e) {
    e.preventDefault();
    setLoading(true);
    setImgError(false);
    try {
      const file = e.target.files[0];
      if (file.size > 2000000) {
        return setImgError(true);
      };
      const resizedFile = await resizeFile(file);
      const base64 = await convertBase64(resizedFile);
      setImage(base64);
    } catch {
      document.getElementById("files").value = "";
    };
    setLoading(false);
  };

  const handleFieldChange = (evt) => {
    evt.preventDefault();
    if (error) {
      setError(null);
    };
    setFields({ ...fields, [evt.target.name]: evt.target.value });
    !notSaved && setNotSaved(true);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (error) {
      setError(null);
    };
    setLoading(true);

    let business = { ...fields };

    if (image) {
      business = { ...business, logo: image };
    };
    const newBusiness = await post("businesses", business);
    history.push("/companies");
  };

  return (
    <>
      <div className="content">
        <CoreSpinner loading={loading} />
        {!loading ?
          <Row>
            <Col md="8">
              <Card>
                <Form onSubmit={onSubmit}>
                  <CardHeader>
                    <h5 className="title">Create Company</h5>
                    {error &&
                      <p style={{ color: "red" }}>{error}</p>
                    }
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="pr-md-1" md="4">
                        <FormGroup>
                          <label>Company Name</label>
                          <Input
                            maxLength={50}
                            placeholder="Business Name"
                            type="text"
                            title=""
                            required={true}
                            id="business_name"
                            label="Business Name"
                            name="business_name"
                            value={fields.business_name}
                            onChange={handleFieldChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-md-1" md="2">
                        <FormGroup>
                          <label>Abbreviated Name</label>
                          <Input
                            placeholder="Abbreviated Name"
                            maxLength={20}
                            type="text"
                            required={true}
                            title=""
                            id="abbrev"
                            label="Abbreviated Name"
                            value={fields.abbrev}
                            name="abbrev"
                            onChange={handleFieldChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ marginLeft: "-3px" }}>
                      <Col className="px-md-1" md="4">
                        <FormGroup>
                          <label>Billing Name</label>
                          <Input
                            placeholder="Billing Name"
                            maxLength={30}
                            type="text"
                            required={false}
                            title=""
                            id="billing_name"
                            label="Billing Name"
                            value={fields.billing_name}
                            name="billing_name"
                            onChange={handleFieldChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <label>Phone 1</label>
                          <Input
                            placeholder="Phone 1"
                            maxLength={15}
                            type="text"
                            title=""
                            required={false}
                            id="phone1"
                            value={fields.phone1}
                            label="Phone 1"
                            name="phone1"
                            onChange={handleFieldChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="4">
                        <FormGroup>
                          <label>Phone 2</label>
                          <Input
                            placeholder="Phone 2"
                            maxLength={15}
                            type="text"
                            required={false}
                            value={fields.phone2}
                            title=""
                            id="phone2"
                            label="Phone 2"
                            name="phone2"
                            onChange={handleFieldChange}
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col className="px-md-1" md="3">
                        <FormGroup>
                          {fields.role &&
                            <label>Role</label>
                          }
                          <br />
                          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle style={{ padding: "10px 18px 10px 18px" }}>{fields.role || "Role"}&nbsp;&nbsp;▼</DropdownToggle>
                            <DropdownMenu>
                              {userTypes.map(type =>
                                <DropdownItem name="role" onClick={handleFieldChange} value={type.value}>{type.label}</DropdownItem>
                              )}
                            </DropdownMenu>
                          </Dropdown>
                        </FormGroup>
                      </Col> */}
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <label>Email 1</label>
                          <Input
                            placeholder="Email 1"
                            maxLength={30}
                            type="email"
                            title=""
                            required={false}
                            id="title"
                            value={fields.email1}
                            label="Email 1"
                            name="email1"
                            onChange={handleFieldChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="4">
                        <FormGroup>
                          <label>Email 2</label>
                          <Input
                            placeholder="Email 2"
                            maxLength={30}
                            type="email"
                            required={false}
                            value={fields.email2}
                            title=""
                            id="email2"
                            label="Email 2"
                            name="email2"
                            onChange={handleFieldChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1" md="4">
                        <FormGroup>
                          <label>Website</label>
                          <Input
                            placeholder="www.google.com"
                            maxLength={30}
                            type="text"
                            required={true}
                            value={fields.website}
                            title=""
                            id="website"
                            label="Website"
                            name="website"
                            onChange={handleFieldChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="4">
                        <FormGroup>
                          <label>Fax</label>
                          <Input
                            placeholder="Fax"
                            maxLength={15}
                            type="text"
                            required={false}
                            value={fields.fax}
                            title=""
                            id="fax"
                            label="Fax"
                            name="fax"
                            onChange={handleFieldChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="8">
                        <FormGroup>
                          <label>Address 1</label>
                          <Input
                            placeholder="Address 1"
                            maxLength={50}
                            type="text"
                            title=""
                            required={false}
                            id="address1"
                            value={fields.address1}
                            label="Address 1"
                            name="address1"
                            onChange={handleFieldChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="8">
                        <FormGroup>
                          <label>Address 2</label>
                          <Input
                            placeholder="Address 2"
                            maxLength={50}
                            type="text"
                            title=""
                            required={false}
                            id="address2"
                            value={fields.address2}
                            label="Address 2"
                            name="address2"
                            onChange={handleFieldChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1" md="3">
                        <FormGroup>
                          <label>City</label>
                          <Input
                            name="city"
                            maxLength={30}
                            id="city"
                            required={false}
                            title=""
                            placeholder="City"
                            value={fields.city}
                            type="text"
                            label="City"
                            onChange={handleFieldChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="2">
                        <FormGroup>
                          <label>State</label>
                          <Input
                            name="state"
                            maxLength={10}
                            id="state"
                            required={false}
                            title=""
                            value={fields.state}
                            placeholder="State"
                            type="text"
                            label="State"
                            onChange={handleFieldChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="3">
                        <FormGroup>
                          <label>Country</label>
                          <Input
                            name="country"
                            maxLength={30}
                            id="country"
                            required={false}
                            title=""
                            value={fields.country}
                            placeholder="Country"
                            type="text"
                            label="Country"
                            onChange={handleFieldChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="2">
                        <FormGroup>
                          <label>Zip Code</label>
                          <Input
                            name="zip"
                            maxLength={10}
                            id="zip"
                            required={false}
                            title=""
                            value={fields.zip}
                            placeholder="Zip Code"
                            type="text"
                            label="Zip Code"
                            onChange={handleFieldChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                    <Col className="pr-md-1" md="12">
                        <FormGroup>
                          <label>Notes</label>
                          <Input
                            name="notes"
                            maxLength={70}
                            id="notes"
                            required={false}
                            title=""
                            value={fields.notes}
                            placeholder="Notes"
                            type="textarea"
                            label="Notes"
                            onChange={handleFieldChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Button disabled={!notSaved} type="submit" className="btn-fill" color="primary">
                      Submit
                    </Button>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-user">
                <CardBody>
                  <CardText />
                  <div className="author">
                    <div className="block block-one" />
                    <div className="block block-two" />
                    <div className="block block-three" />
                    <div className="block block-four" />
                    <Row>
                      <Col>
                        <form>
                          <label style={{ margin: "0 auto", marginBottom: 20 }} for="files" className="btn">Load Company Logo</label>
                          <input id="files" accept="image/png, image/jpeg" type="file" onChange={handleImageChange} style={{ display: "none" }} />
                        </form>
                        {imgError &&
                          <p style={{ color: "red", marginBottom: 15 }}>The image size is too big</p>
                        }
                      </Col>
                    </Row>
                    <img
                      alt="..."
                      className="avatar"
                      src={image || require("../../assets/img/default-avatar.png").default}
                    />
                    <h4 className="title">{(fields.business_name || "(Company Name)") + " " + (fields.last_name || "")}</h4>
                    <p className="description">{fields.abbrev || ""}</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          :
          null
        }
      </div>
    </>
  );
}

export default CreateBusiness;
