import React, { useState, useEffect } from 'react';
import CoreSpinner from '../../components/_core/CoreSpinner';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { resizeFile, convertBase64 } from '../../actions/helpers';
import { get, post, put, deleteItem } from '../../actions/api';
import { isAuth } from '../../actions/auth';


const styles = {
  container: {
    boxShadow: '0 1px 15px 0 rgba(123, 123, 123, 0.05)',
    marginLeft: '280px',
    marginRight: '60px',
    marginTop: '80px',
    marginBottom: '130px'
    //paddingLeft:'60px',
    //paddingRight:'120px'
  },

  User_image: {
    position: 'absolute',
    background: 'white',
    borderRadius: '50%',
    zIndex: '3000',
    width: '35px',
    height: '35px',
    padding: '0px',
    marginLeft: '20px',
    color: 'black'
  },
  Form_Container: {
    marginLeft: '-200px',
    marginRight: '40px',
    background: '#d4d2d2',
    padding: '40px',
    height: '530px',
    display: 'flex',
    justifyContent: 'space-around',
    paddingLeft: '0px',
    paddingRight: '90px'
  },
  Form_Container2: {
    marginLeft: '-200px',
    marginRight: '40px',
    background: '#d4d2d2',
    padding: '40px',
    height: '300px',
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingLeft: '0px',
    paddingRight: '90px'
  },
  Name_Container: {
    width: '240.83px',
    borderRadius: '5px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    // height:'60px'
  },
  Name_Text: {
    background: 'radial-gradient(540.79% 22640.32% at 10.33% -567.11%, rgba(94, 114, 228, 1) 0%, rgba(52, 246, 242, 1) 55.73%, rgba(94, 114, 228, 1) 100%)',
    padding: '5px 0',
    textAlign: 'center',
    borderRadius: '5px',
    color: '#fff',
    marginBottom: '14px',
    width: '100%',
    // height: '46px',
    fontSize: '22px',
    paddingLeft: '0px'
  },
  NameIcon: {

    float: 'right',
    marginRight: '10px',
    marginTop: '6px'
  },
  UserName_Container: {
    width: '240.83px',
    background: '#fff',
    borderRadius: '5px',
    marginBottom: '8px',
    padding: '6px',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center'

  },
  UserName_Text: {
    display: 'inline-block',
    fontSize: '12px',
    margin: '5px',
    width: '72px'
  },
  FirstName_Container: {
    width: '240.83px',
    background: '#fff',
    borderRadius: '5px',
    marginBottom: '8px',
    padding: '6px',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    marginTop: '20px'
  },
  FirstName_Text: {
    display: 'inline-block',
    fontSize: '12px',
    margin: '5px',
    width: '93px'
  },
  LastName_Container: {
    width: '240.83px',
    background: '#fff',
    borderRadius: '5px',
    marginBottom: '8px',
    padding: '6px',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    marginTop: '20px'
  },
  LastName_Text: {
    display: 'inline-block',
    fontSize: '12px',
    margin: '5px',
    width: '99px'
  },
  Title_Container: {
    width: '300px',
    //  height: '60px',
    borderRadius: '5px',
    textAlign: 'center',
    display: 'flex'

  },
  Title_Text: {
    background: 'radial-gradient(540.79% 22640.32% at 10.33% -567.11%, rgba(94, 114, 228, 1) 0%, rgba(52, 246, 242, 1) 55.73%, rgba(94, 114, 228, 1) 100%)',
    padding: '5px 0',
    textAlign: 'center',
    borderRadius: '5px',
    color: '#fff',
    marginBottom: '14px',
    width: '80%',
    //  height: '46px',
    fontSize: '22px',
    paddingLeft: '0px'
  },
  TitelIcon: {
    float: 'right',
    marginRight: '10px',
    marginTop: '6px'
  },
  Title_Input_Container: {
    width: '193px',
    background: '#fff',
    borderRadius: '5px',
    marginBottom: '8px',
    padding: '6px',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center'
  },
  Role_Container: {
    width: '300px',
    //   height: '60px',
    borderRadius: '5px',
    textAlign: 'center',
    display: 'flex'
  },
  Role_Text: {
    background: 'radial-gradient(540.79% 22640.32% at 10.33% -567.11%, rgba(94, 114, 228, 1) 0%, rgba(52, 246, 242, 1) 55.73%, rgba(94, 114, 228, 1) 100%)',
    padding: '5px 0',
    textAlign: 'center',
    borderRadius: '5px',
    color: '#fff',
    marginBottom: '14px',
    width: '80%',
    // height: '46px',
    fontSize: '22px',
    paddingLeft: '0px'

  },
  Role_Icon: {
    float: 'right',
    marginRight: '0px',
    marginTop: '6px'
  },
  Role_Input_Container: {
    width: '160px',
    background: '#fff',
    borderRadius: '5px',
    marginBottom: '8px',
    //  padding: '6px',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center'
  },
  blank_box: {
    width: '240.83px',
    //  height: '38px',
    marginTop: '20px',
    display: 'flex',
    background: '#6b6d70',
    borderRadius: '5px',
    // padding:'6px',
    // display: 'flex',
    // textAlign: 'center',
    // alignItems: 'center'
    height: '50px'
  },
  Email_Text: {
    display: 'inline-block',
    fontSize: '12px',
    margin: '7px 6px 6px 6px',
    width: '72px'
  },
  Email_Container: {
    width: '520px',
    background: '#fff',
    borderRadius: '5px',
    marginBottom: '8px',
    padding: '6px',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center'

  },
  Contact_Container: {
    width: '520px',
    // height: '60px',
    borderRadius: '5px',
    textAlign: 'center',
    display: 'flex'
  },
  Contact_Text: {
    background: 'radial-gradient(540.79% 22640.32% at 10.33% -567.11%, rgba(94, 114, 228, 1) 0%, rgba(52, 246, 242, 1) 55.73%, rgba(94, 114, 228, 1) 100%)',
    padding: '5px 0',
    textAlign: 'center',
    borderRadius: '5px',
    color: '#fff',
    marginBottom: '14px',
    width: '100%',
    //   height: '46px',
    fontSize: '22px',
    paddingLeft: '30px'

  },
  Contact_Icon: {
    float: 'right',
    marginRight: '10px',
    marginTop: '6px'
  }

}


function EditCompany(props) {
  const id = props.match.params.id;
  const [error, setError] = useState(null);
  const [fields, setFields] = useState(null);
  const [business, setBusiness] = useState(null);
  const [notSaved, setNotSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imgError, setImgError] = useState(null);

  useEffect(() => {
    if (!fields) {
      getBusinessData();
    };
  });

  async function getBusinessData() {
    const user = isAuth();
    setFields(user.business);
    setBusiness(user.business);
  };

  function cancel() {
    setFields(business);
  };

  async function handleImageChange(e) {
    setLoading(true);
    setImgError(false);
    let image;
    try {
      const file = e.target.files[0];
      if (file.size > 2000000) {
        return setImgError(true);
      };
      const resizedFile = await resizeFile(file);
      image = resizedFile;
    } catch {
      document.getElementById("files").value = "";
    };
    let base64;
    if (image) {
      base64 = await convertBase64(image);
      await put("/businesses", { id: fields.id, logo: base64 });
    };
    let user = isAuth();
    user.business.logo = base64;
    localStorage.setItem("user", JSON.stringify(user));
    window.location.reload();
  };

  const handleFieldChange = (evt) => {
    error && setError(null);
    evt.preventDefault();
    setFields({ ...fields, [evt.target.name]: evt.target.value });
    !notSaved && setNotSaved(true);
  };

  const onSubmit = async (e) => {
    setLoading(true);
    if (error) {
      setError(null);
    };

    const excluded = [];
    const filteredFields = {};
    for (let [key, value] of Object.entries(fields)) {
      if (!(excluded.includes(key)) && fields[key] !== business[key]) {
        filteredFields[key] = value;
      };
    };
    filteredFields["id"] = fields.id;
    await put("/businesses", filteredFields);
    let user = isAuth();
    user.business = fields;
    localStorage.setItem("user", JSON.stringify(user));
    window.location.reload();
  };

  return (
    <>
      <div className="content" style={styles.container}>
        {/* {fields &&
          <div>
            <button className={fields.enabled ? 'btn btn-danger' : 'btn btn-primary'} style={{ float: "left", marginBottom: 200, marginLeft: -200, zIndex: 1, position: 'absolute', padding: "10px 20px 10px 20px" }} onClick={toggleUser}>{fields.enabled ? "Disable" : "Enable"} User</button>
          </div>
        } */}
        {loading && <CoreSpinner loading={loading} />}
        {fields && !loading ?
          <>
            <Row style={{ marginTop: '-80px' }}>
              <Col md="12">
                <Card className="card-user" style={{ marginLeft: '-145px', backgroundColor: "rgb(255, 255, 255, 0)" }}>
                  <CardBody>
                    <div className="author">
                      <Row >
                        <Col>
                          <form>
                            <label style={styles.User_image} htmlFor="files" className="btn">
                              <EditIcon color='black' style={{marginTop: "5px"}} /> </label>
                            <input accept="image/png, image/jpeg" id="files" type="file" onChange={handleImageChange} style={{ display: "none" }} />
                          </form>
                          {imgError &&
                            <p style={{ color: "red", marginBottom: 15 }}>The image size is too big</p>
                          }
                        </Col>
                      </Row>
                      <img
                        alt="..."
                        className="avatar2"
                        src={fields.logo || require("../../assets/img/default-avatar.png").default}
                      />
                      <h4 className="title">{fields.first_name} {fields.last_name && <> {" " + fields.last_name} </>}</h4>
                      <p className="description" style={{ fontWeight: 'bold' }}>{fields.title || null}</p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Form onSubmit={onSubmit}>

              <Row style={styles.Form_Container} around="xs">
                {/* <Col md="12" style={{background:'#afb1b3',display:'none'}}>
                <Card style={{background:'#afb1b3'}}>
                  <Form onSubmit={onSubmit}>
                    <CardHeader>
                      <h5 className="title">{isMe ? "My Profile" : "Edit User"}</h5>
                      {error &&
                        <p style={{ color: "red" }}>{error}</p>
                      }
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="pr-md-1" md="3">
                          <label>Email</label>
                          <p>{fields.email}</p>
                        </Col>
                        <Col className="pr-md-1" md="3">
                          <FormGroup>
                            <label>First Name</label>
                            <Input
                              placeholder="First Name"
                              type="text"
                              required={true}
                              maxLength={20}
                              id="first_name"
                              label="First Name"
                              name="first_name"
                              onChange={handleFieldChange}
                              value={fields.first_name}
                            />
                          </FormGroup>
                        </Col>
                        <Col className="px-md-1" md="3">
                          <FormGroup>
                            <label>Last Name</label>
                            <Input
                              placeholder="Last Name"
                              type="text"
                              required={false}
                              maxLength={20}
                              id="last_name"
                              label="Last Name"
                              name="last_name"
                              onChange={handleFieldChange}
                              value={fields.last_name}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2">
                          <FormGroup>
                            <label>Title</label>
                            <Input
                              placeholder="Title"
                              type="text"
                              required={false}
                              id="title"
                              maxLength={20}
                              label="Title"
                              name="title"
                              onChange={handleFieldChange}
                              value={fields.title}
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pr-md-1" md="4">
                          <FormGroup>
                            <label>Phone</label>
                            <Input
                              placeholder="Phone"
                              type="text"
                              required={false}
                              id="phone"
                              maxLength={20}
                              label="Phone"
                              name="phone"
                              onChange={handleFieldChange}
                              value={fields.phone}
                            />
                          </FormGroup>
                        </Col>
                        <Col className="px-md-1" md="3">
                          <FormGroup>
                            <label>Role</label>
                            <br />
                            {!isMe ?
                              <Dropdown value={fields.role} isOpen={dropdownOpen} toggle={toggle}>
                                <DropdownToggle style={{ padding: "10px 18px 10px 18px" }}>{fields.role}&nbsp;&nbsp;▼</DropdownToggle>
                                <DropdownMenu>
                                  {userTypes.map(type =>
                                    <DropdownItem name="role" selected={fields.role === type.value} onClick={handleFieldChange} value={type.value}>{type.label}</DropdownItem>
                                  )}
                                </DropdownMenu>
                              </Dropdown>
                              :
                              <p>{fields.role}</p>
                            }
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <Button disabled={!notSaved} type="submit" className="btn-fill" color="primary">
                        Save
                      </Button>
                    </CardFooter>
                  </Form>
                </Card>
              </Col> */}
                <Col xs={2} >

                  <div style={styles.Name_Container}> <span style={styles.Name_Text}  >Company Info</span></div>


                  <FormGroup
                    style={styles.UserName_Container}>
                    <label
                      style={styles.UserName_Text}
                    >Full Company Name</label>
                    <Input
                      style={{ color: 'black', border: 'none', padding: '10px', backgroundColor: "" }}
                      placeholder="Full Company Name"
                      type="text"
                      required={true}
                      maxLength={100}
                      id="business_name"
                      label="Full Company Name"
                      name="business_name"
                      onChange={handleFieldChange}
                      value={fields.business_name}

                    />
                  </FormGroup>

                  <FormGroup
                    style={styles.FirstName_Container}>
                    <label style={styles.FirstName_Text}  >Abbreviated Company Name</label>
                    <Input
                      style={{ color: 'black', border: 'none', padding: '10px' }}
                      placeholder="Abbreviated Company Name"
                      type="text"
                      required={true}
                      maxLength={50}
                      id="abbrev"
                      label="Abbreviated Company Name"
                      name="abbrev"
                      onChange={handleFieldChange}
                      value={fields.abbrev}
                    />
                  </FormGroup>
                  <FormGroup
                    style={styles.LastName_Container}>
                    <label

                      style={styles.LastName_Text} >Billing Name</label>
                    <Input
                      style={{ color: 'black', border: 'none', padding: '10px' }}
                      placeholder="Billing Name"
                      type="text"
                      required={false}
                      maxLength={50}
                      id="billing_name"
                      label="Billing Name"
                      name="billing_name"
                      onChange={handleFieldChange}
                      value={fields.billing_name}
                    />
                  </FormGroup>

                </Col>
                <Col xs={2}  >

                  <div style={styles.Title_Container}> <span style={styles.Title_Text} >Contact</span></div>

                  <FormGroup
                    style={styles.LastName_Container}>
                    <label

                      style={styles.LastName_Text} >Phone 1</label>
                    <Input
                      style={{ color: 'black', border: 'none', padding: '10px' }}
                      placeholder="Phone 1"
                      type="text"
                      required={false}
                      maxLength={20}
                      id="billing_name"
                      label="Phone 1"
                      name="phone1"
                      onChange={handleFieldChange}
                      value={fields.phone1}
                    />
                  </FormGroup>
                  <FormGroup
                    style={styles.LastName_Container}>
                    <label

                      style={styles.LastName_Text} >Phone 2</label>
                    <Input
                      style={{ color: 'black', border: 'none', padding: '10px' }}
                      placeholder="Phone 2"
                      type="text"
                      required={false}
                      maxLength={20}
                      id="phone2"
                      label="Phone 2"
                      name="phone2"
                      onChange={handleFieldChange}
                      value={fields.phone2}
                    />
                  </FormGroup>
                  <FormGroup
                    style={styles.LastName_Container}>
                    <label

                      style={styles.LastName_Text} >Email 1</label>
                    <Input
                      style={{ color: 'black', border: 'none', padding: '10px' }}
                      placeholder="Email 1"
                      type="text"
                      required={false}
                      maxLength={30}
                      id="email1"
                      label="Email 1"
                      name="email1"
                      onChange={handleFieldChange}
                      value={fields.email1}
                    />
                  </FormGroup>
                  <FormGroup
                    style={styles.LastName_Container}>
                    <label

                      style={styles.LastName_Text} >Email 2</label>
                    <Input
                      style={{ color: 'black', border: 'none', padding: '10px' }}
                      placeholder="Email 2"
                      type="text"
                      required={false}
                      maxLength={30}
                      id="email2"
                      label="Email 2"
                      name="email2"
                      onChange={handleFieldChange}
                      value={fields.email2}
                    />
                  </FormGroup>
                  <FormGroup
                    style={styles.LastName_Container}>
                    <label

                      style={styles.LastName_Text} >Website</label>
                    <Input
                      style={{ color: 'black', border: 'none', padding: '10px' }}
                      placeholder="Website"
                      type="text"
                      required={false}
                      maxLength={50}
                      id="website"
                      label="Website"
                      name="website"
                      onChange={handleFieldChange}
                      value={fields.website}
                    />
                  </FormGroup>
                  <FormGroup
                    style={styles.LastName_Container}>
                    <label

                      style={styles.LastName_Text} >Fax</label>
                    <Input
                      style={{ color: 'black', border: 'none', padding: '10px' }}
                      placeholder="Fax"
                      type="text"
                      required={false}
                      maxLength={20}
                      id="fax"
                      label="Fax"
                      name="fax"
                      onChange={handleFieldChange}
                      value={fields.fax}
                    />
                  </FormGroup>
                </Col>
          
                <Col xs={2} style={{}}>
                  <div style={styles.Role_Container}> <span style={styles.Role_Text}  >Address</span></div>
                  <FormGroup
                    style={styles.LastName_Container}>
                    <label

                      style={styles.LastName_Text} >Address 1</label>
                    <Input
                      style={{ color: 'black', border: 'none', padding: '10px' }}
                      placeholder="Address 1"
                      type="text"
                      required={false}
                      maxLength={100}
                      id="address1"
                      label="Address 1"
                      name="address1"
                      onChange={handleFieldChange}
                      value={fields.address1}
                    />
                  </FormGroup>
                  <FormGroup
                    style={styles.LastName_Container}>
                    <label
                      style={styles.LastName_Text} >Address 2</label>
                    <Input
                      style={{ color: 'black', border: 'none', padding: '10px' }}
                      placeholder="Address 2"
                      type="text"
                      required={false}
                      maxLength={100}
                      id="address2"
                      label="Address 2"
                      name="address2"
                      onChange={handleFieldChange}
                      value={fields.address2}
                    />
                  </FormGroup>
                  <FormGroup
                    style={styles.LastName_Container}>
                    <label
                      style={styles.LastName_Text} >City</label>
                    <Input
                      style={{ color: 'black', border: 'none', padding: '10px' }}
                      placeholder="City"
                      type="text"
                      required={false}
                      maxLength={30}
                      id="city"
                      label="City"
                      name="city"
                      onChange={handleFieldChange}
                      value={fields.city}
                    />
                  </FormGroup>
                  <FormGroup
                    style={styles.LastName_Container}>
                    <label
                      style={styles.LastName_Text} >State</label>
                    <Input
                      style={{ color: 'black', border: 'none', padding: '10px' }}
                      placeholder="State"
                      type="text"
                      required={false}
                      maxLength={30}
                      id="state"
                      label="State"
                      name="state"
                      onChange={handleFieldChange}
                      value={fields.state}
                    />
                  </FormGroup>
                  <FormGroup
                    style={styles.LastName_Container}>
                    <label

                      style={styles.LastName_Text} >Country</label>
                    <Input
                      style={{ color: 'black', border: 'none', padding: '10px' }}
                      placeholder="Country"
                      type="text"
                      required={false}
                      maxLength={50}
                      id="country"
                      label="Country"
                      name="country"
                      onChange={handleFieldChange}
                      value={fields.country}
                    />
                  </FormGroup>
                  <FormGroup
                    style={styles.LastName_Container}>
                    <label

                      style={styles.LastName_Text} >Zip Code</label>
                    <Input
                      style={{ color: 'black', border: 'none', padding: '10px' }}
                      placeholder="Zip Code"
                      type="text"
                      required={false}
                      maxLength={20}
                      id="zip"
                      label="Zip Code"
                      name="zip"
                      onChange={handleFieldChange}
                      value={fields.zip}
                    />
                  </FormGroup>

                </Col>
              </Row>
              <Row style={styles.Form_Container2} around="xs">
                <Col xs={2} style={{}} >
                <div style={styles.Contact_Container}> <span style={styles.Contact_Text} >Notes</span></div>
                <FormGroup 
                  style={styles.Email_Container}>
                            <textarea
                              style={{ color:'black',  border:'none',padding:'3px', width:'520px'}}
                              placeholder="Notes"
                              type="text"
                              required={false}
                              maxLength={1000}
                              id="notes"
                              label="Notes"
                              name="notes"
                              onChange={handleFieldChange}
                              value={fields.notes}
                            />
                </FormGroup>
            </Col>
            </Row>
            </Form>

            <Row style={{ marginBottom: 10, marginLeft: '-120px', marginRight: '100px', padding: '40px', height: '10px', display: 'flex', justifyContent: 'space-evenly', marginTop: -100 }} around="xs" >

              <Button disabled={fields === business} style={{ background: 'rgba(108, 133, 215, 1)', height: '40px' }} onClick={cancel} type="submit" className="btn-fill" color="primary">
                Cancel
              </Button>
              {/* <Button disabled  style={{background: 'rgba(108, 133, 215, 1)',height:'40px'}}  type="submit" className="btn-fill" color="primary">
                        Wallet
                      </Button> */}
              <Button disabled={!notSaved} onClick={onSubmit} style={{ background: 'rgba(108, 133, 215, 1)', color: 'whitesmoke', height: '40px' }} type="submit" className="btn-fill" color="primary">
                Save
              </Button>

            </Row>

          </>
          :
          null
        }
      </div>
    </>
  );
}

export default EditCompany;
