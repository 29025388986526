import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { isAuth, isUserSuperadmin } from '../../actions/auth';
import CoreSpinner from '../../components/_core/CoreSpinner';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";
import { convertBase64, resizeFile } from '../../actions/helpers';
import { get, post, put, deleteItem } from '../../actions/api';


function CreateUser(props) {
  const [error, setError] = useState(null);
  const [fields, setFields] = useState({});
  const [notSaved, setNotSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imgError, setImgError] = useState(null);
  const [image, setImage] = useState(null);
  const [idleUsers, setIdleUsers] = useState(null);
  let history = useHistory();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const emailToggle = () => setDropdownOpen2(prevState => !prevState);

  const userTypes = [
    { value: 'superadmin', label: 'Superadmin' },
    { value: 'admin', label: 'Admin' },
    { value: 'user', label: 'User' }
  ];

  useEffect(() => {
    if (isUserSuperadmin()) {
      getIdleUsers();
    }
  }, [])


  async function getIdleUsers() {
    const idleUsers = await get("/users/idle");
    setIdleUsers(idleUsers);
  }

  async function handleImageChange(e) {
    e.preventDefault();
    setLoading(true);
    setImgError(false);
    try {
      const file = e.target.files[0];
      if (file.size > 2000000) {
        return setImgError(true);
      };
      const resizedFile = await resizeFile(file);
      const base64 = await convertBase64(resizedFile);
      setImage(base64);
    } catch {
      document.getElementById("files").value = "";
    };
    setLoading(false);
  };

  const handleFieldChange = (evt) => {
    evt.preventDefault();
    if (error) {
      setError(null);
    };
    setFields({ ...fields, [evt.target.name]: evt.target.value });
    !notSaved && setNotSaved(true);
  };

  const emailClicked = (evt) => {
    evt.preventDefault();
    if (error) {
      setError(null);
    };
    setFields({ ...fields, email: evt.target.value });
    !notSaved && setNotSaved(true);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (error) {
      setError(null);
    };
    setLoading(true);

    if (isUserSuperadmin() && !fields.role) {
      setLoading(false);
      return setError("You have to set a role for this user");
    };

    let user = { ...fields };

    if (user.email !== user.verifyEmail) {
      setLoading(false);
      return setError("Emails do not match!");
    };

    delete user["verifyEmail"];

    if (image) {
      user = { ...user, profile_picture: image };
    };

    await post("/users" + (isUserSuperadmin() ? `?business_id=${isAuth().business.id}` : ""), user);
    history.push("/users");
  };

  return (
    <>
      <div className="content">
        {loading && <CoreSpinner loading={loading} />}
        {!loading ?
          <Row>
            <Col md="8">
              <Card>
                <Form onSubmit={onSubmit}>
                  <CardHeader>
                    <h5 className="title">Create User</h5>
                    {error &&
                      <p style={{ color: "red" }}>{error}</p>
                    }
                    {(idleUsers?.length > 0 && isUserSuperadmin()) &&
                      <Dropdown isOpen={dropdownOpen2} toggle={emailToggle} style={{ float: "right" }}>
                        <DropdownToggle style={{ padding: "10px 18px 10px 18px" }}>Import Email&nbsp;&nbsp;▼</DropdownToggle>
                        <DropdownMenu>
                          {idleUsers.map(user =>
                            <DropdownItem name="role" onClick={emailClicked} value={user.email}>{user.email}</DropdownItem>
                          )}
                        </DropdownMenu>
                      </Dropdown>
                    }
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="pr-md-1" md="4">
                        <FormGroup>
                          <label>First Name</label>
                          <Input
                            maxLength={20}
                            placeholder="First Name"
                            type="text"
                            title=""
                            required={true}
                            id="first_name"
                            label="First Name"
                            name="first_name"
                            value={fields.first_name}
                            onChange={handleFieldChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-md-1" md="4">
                        <FormGroup>
                          <label>Last Name</label>
                          <Input
                            placeholder="Last Name"
                            maxLength={20}
                            type="text"
                            required={false}
                            title=""
                            id="last_name"
                            label="Last Name"
                            value={fields.last_name}
                            name="last_name"
                            onChange={handleFieldChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <FormGroup>
                          <label>Title</label>
                          <Input
                            placeholder="Title"
                            maxLength={20}
                            type="text"
                            title=""
                            required={false}
                            id="title"
                            value={fields.title}
                            label="Title"
                            name="title"
                            onChange={handleFieldChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="4">
                        <FormGroup>
                          <label>Phone</label>
                          <Input
                            placeholder="Phone"
                            maxLength={20}
                            type="text"
                            required={false}
                            value={fields.phone}
                            title=""
                            id="phone"
                            label="Phone"
                            name="phone"
                            onChange={handleFieldChange}
                          />
                        </FormGroup>
                      </Col>
                      {isUserSuperadmin() &&
                        <Col className="px-md-1" md="3">
                          <FormGroup>
                            {fields.role &&
                              <label>Role</label>
                            }
                            <br />
                            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                              <DropdownToggle style={{ padding: "10px 18px 10px 18px" }}>{fields.role || "Role"}&nbsp;&nbsp;▼</DropdownToggle>
                              <DropdownMenu>
                                {userTypes.map(type =>
                                  <DropdownItem name="role" onClick={handleFieldChange} value={type.value}>{type.label}</DropdownItem>
                                )}
                              </DropdownMenu>
                            </Dropdown>
                          </FormGroup>
                        </Col>
                      }
                    </Row>
                    <Row>
                      <Col className="pr-md-1" md="4">
                        <FormGroup>
                          <label>Email</label>
                          <Input
                            name="email"
                            maxLength={70}
                            id="email"
                            required={true}
                            title=""
                            placeholder="Email"
                            value={fields.email}
                            type="email"
                            label="Email"
                            onChange={handleFieldChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="4">
                        <FormGroup>
                          <label>Verify Email</label>
                          <Input
                            name="verifyEmail"
                            maxLength={70}
                            id="verifyEmail"
                            required={true}
                            title=""
                            value={fields.verifyEmail}
                            placeholder="Verify Email"
                            type="email"
                            label="Verify Email"
                            onChange={handleFieldChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Button disabled={!notSaved} type="submit" className="btn-fill" color="primary">
                      Submit
                    </Button>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-user">
                <CardBody>
                  <CardText />
                  <div className="author">
                    <div className="block block-one" />
                    <div className="block block-two" />
                    <div className="block block-three" />
                    <div className="block block-four" />
                    <Row>
                      <Col>
                        <form>
                          <label style={{ margin: "0 auto", marginBottom: 20 }} for="files" className="btn">Load Profile Photo</label>
                          <input id="files" accept="image/png, image/jpeg" type="file" onChange={handleImageChange} style={{ display: "none" }} />
                        </form>
                        {imgError &&
                          <p style={{ color: "red", marginBottom: 15 }}>The image size is too big</p>
                        }
                      </Col>
                    </Row>
                    <img
                      alt="..."
                      className="avatar"
                      src={image || require("../../assets/img/default-avatar.png").default}
                    />
                    <h4 className="title">{(fields.first_name || "Name Surname") + " " + (fields.last_name || "")}</h4>
                    <p className="description">{fields.title || "Title"}</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          :
          null
        }
      </div>
    </>
  );
}

export default CreateUser;
