import { IconButton, Button } from '@mui/material';
import Modal from "react-bootstrap/Modal";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { isUserAdmin } from '../../actions/auth';
import CoreSpinner from '../../components/_core/CoreSpinner';
import EditIcon from '@mui/icons-material/Edit';
import { Delete } from '@mui/icons-material';
import PrintIcon from '@mui/icons-material/Print';
import { get, post, put, deleteItem } from '../../actions/api';
import {
    Card,
    CardHeader,
    CardBody,
    Col,
} from "reactstrap";
import { ThemeContext } from '../../contexts/ThemeContext';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';


const TemplateGridItem = ({ item, preview, setPreview }) => {
    const isAdmin = isUserAdmin();
    const [deleting, setDeleting] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const theme = React.useContext(ThemeContext);
    const isDark = theme.theme === "" ? true : false;
    const styles = {
        Container: {
            width: '300px',
            height: '403px',
            marginRight: '15px',
            marginLeft: '15px',
            marginTop: '25px',
            marginBottom: '25px',
            border: '0.5px solid #626262'
        },
        Card: {
            width: '300px',
            height: '52.96px',
            marginLeft: '0px',
            background: 'rgba(98, 98, 98, 0.2)',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
        },
        Text: {
            color: isDark ? 'white' : 'black',
            fontSize: '17px',
        },
        DelIcon: {
            color: 'red',
            fontSize: 17,
            marginTop: '-8px',
            marginLeft: '-15px',
            marginRight: '5px',
            float: 'left'
        }
    }

    async function deleteTemp() {
        setDeleting(true);
        await deleteItem("/templates?id=" + item.id);
        setDeleting(false);
        setOpenModal(false);
        window.location.reload();
    };

    return (
        <>


            <Col md={"2.9"}

                style={styles.Container}>
                <Card>
                    <CardHeader
                        style={styles.Card}>
                        {isAdmin &&
                            <Modal style={{ marginTop: 100 }} show={openModal}>
                                <Modal.Header>
                                    {item.archived ?
                                        <Modal.Title style={{ color: "primary", marginBottom: 5 }}>Restore</Modal.Title>
                                        :
                                        <Modal.Title style={{ color: "red", marginBottom: 5 }}>Delete</Modal.Title>
                                    }

                                </Modal.Header>
                                <Modal.Body>
                                    Are you sure you want to  {item.archived ? "restore" : "eliminate"}:<br />
                                    <strong>{item.title}</strong>?<br />
                                </Modal.Body>
                                <Modal.Footer style={{ marginBottom: 25 }}>
                                    {deleting && <CoreSpinner loading={deleting} />}
                                    <Button style={{ marginLeft: "15px" }} color="primary" onClick={() => { setOpenModal(false) }}>
                                        Cancel
                                    </Button>
                                    {
                                        item.archived ?
                                            <Button color="primary" style={{ marginRight: "15px" }} onClick={deleteTemp}>
                                                Restore
                                            </Button>
                                            :
                                            <Button color="error" style={{ marginRight: "15px" }} onClick={deleteTemp}>
                                                Delete
                                            </Button>
                                    }

                                </Modal.Footer>
                            </Modal>
                        }
                        {/* <span style={{color:'red',float:'left',marginTop:'-5px',marginLeft:'-5px'}}>Delete</span> */}
                        <IconButton onClick={() => { setOpenModal(true) }} title="Restore" color='error' style={styles.DelIcon} >      {isAdmin ? <>{item.archived ? <RestoreFromTrashIcon color='primary' /> : <Delete />}</> : <PrintIcon />}                  </IconButton>
                        <h4 style={styles.Text}>{item.title}</h4>
                        {/* <span style={{color:'red',float:'right',marginTop:'-40px'}}>Edit</span> */}
                        <IconButton title="Edit" style={{ fontSize: 17, marginTop: '-50px', float: 'right' }} component={Link} to={{ pathname: "/builder", state: { id: item.id } }} color="primary">      {isAdmin ? <EditIcon /> : <PrintIcon />}                  </IconButton>

                    </CardHeader>
                    <CardBody onClick={() => { setPreview(item) }}>

                        <iframe title="Preview" srcDoc={item.template} style={{ width: "100%", height: "300px", backgroundColor: "white", border: "0px solid grey", fontSize: '12px', fontFamily: 'sans-serif', lineHeight: '1.34' }} />
                        {/* {templates.map(template => <TemplateListItem item={template} preview={preview} setPreview={setPreview} key={template.id} />)} */}
                    </CardBody>
                </Card>
            </Col>

        </>
    );

};

export default TemplateGridItem;