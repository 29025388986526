import React from "react";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { isAuth, isUserAdmin, isUserSuperadmin } from "../actions/auth";
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from "react-router-dom";
import { IconButton } from '@mui/material';


function Company() {

  const business = isAuth().business;
  const history = useHistory();

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">

            <Card>
              <CardHeader className="mb-5">
            
                <h5 className="card-category">{business.abbrev}</h5>
                <CardTitle tag="h3">
                  {business.business_name !== "" ? business.business_name : "-"}
                  {isUserAdmin() || isUserSuperadmin() ?
            <IconButton style={{ marginBottom: 5, marginLeft: 20}} onClick={() => history.push('/edit_company')}><EditIcon style={{fontSize: 27, color: "rgb(108, 133, 215)"}} /></IconButton>
            :
            null
        }
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="typography-line">
                  <p>
                    <span>License</span>
                    {business.license ? business.license : "-"}
                  </p>
                </div>
                <div className="typography-line">
                  <p>
                    <span>Billing Name</span>
                    {business.billing_name ? business.billing_name : "-"}
                  </p>
                </div>
                <div className="typography-line">
                  <p>
                    <span>Email 1</span>
                    {business.email1 ? business.email1 : "-"}
                  </p>
                </div>
                <div className="typography-line">
                  <p>
                    <span>Email 2</span>
                    {business.email2 ? business.email2 : "-"}
                  </p>
                </div>
                <div className="typography-line">
                  <p>
                    <span>Website</span>
                    <a target="_blank" rel="noreferrer" href={business.website ?? ''}>{business.website ? business.website : "-"}</a>
                  </p>
                </div>
                <div className="typography-line">
                  <p>
                    <span>Phone 1</span>
                    {business.phone1 ? business.phone1 : "-"}
                  </p>
                </div>
                <div className="typography-line">
                  <p>
                    <span>Phone 2</span>
                    {business.phone1 ? business.phone1 : "-"}
                  </p>
                </div>
                <div className="typography-line">
                  <p>
                    <span>Fax</span>
                    {business.fax ? business.fax : "-"}
                  </p>
                </div>
                <div className="typography-line">
                  <p>
                    <span>Address 1</span>
                    {business.address1 ? business.address1 : "-"}
                  </p>
                </div>
                <div className="typography-line">
                  <p>
                    <span>Address 2</span>
                    {business.address2 ? business.address2 : "-"}
                  </p>
                </div>
                <div className="typography-line">
                  <p>
                    <span>City</span>
                    {business.city ? business.city : "-"}
                  </p>
                </div>
                <div className="typography-line">
                  <p>
                    <span>Zip</span>
                    {business.zip ? business.zip : "-"}
                  </p>
                </div>
                <div className="typography-line">
                  <p>
                    <span>State</span>
                    {business.state ? business.state : "-"}
                  </p>
                </div>
                <div className="typography-line">
                  <p>
                    <span>Country</span>
                    {business.country ? business.country : "-"}
                  </p>
                </div>

                { business.notes &&
                <div className="typography-line">
                  <span>Notes</span>
                  <blockquote>
                    <p className="blockquote blockquote-primary">
                      {business.notes !== "" ? business.notes : "-"}
                    </p>
                  </blockquote>
                </div>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Company;
