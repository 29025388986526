import { IconButton, Button } from '@mui/material';
import { Delete } from '@mui/icons-material';
import Modal from "react-bootstrap/Modal";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { isUserAdmin } from '../../actions/auth';
import CoreSpinner from '../../components/_core/CoreSpinner';
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { get, post, put, deleteItem } from '../../actions/api';
import { convertUTCDateToLocalDate } from '../../actions/helpers';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';


const TemplateListItem = ({ item, preview, setPreview }) => {
    const isAdmin = isUserAdmin();
    const [deleting, setDeleting] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const created = new Date(item.created_at);
    const created_at = created.toString().slice(0, 3) + ", " + convertUTCDateToLocalDate(created).toLocaleString().slice(0, -3);
    const updated = new Date(item.updated_at);
    const updated_at = updated.toString().slice(0, 3) + ", " + convertUTCDateToLocalDate(updated).toLocaleString().slice(0, -3);

    async function deleteTemp() {
        setDeleting(true);
        await deleteItem("/templates?id=" + item.id);
        setDeleting(false);
        setOpenModal(false);
        window.location.reload();
    };

    return (
        <>
            {isAdmin &&
                <Modal style={{ marginTop: 100 }} show={openModal}>
                    <Modal.Header>
                        { item.archived ?
                             <Modal.Title style={{ color: "primary", marginBottom: 5 }}>Restore</Modal.Title>
                             :
                             <Modal.Title style={{ color: "red", marginBottom: 5 }}>Delete</Modal.Title>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to {item.archived ? "restore": "delete"}:<br />
                        <strong>{item.title}</strong>?<br />
                    </Modal.Body>
                    <Modal.Footer style={{ marginBottom: 25 }}>
                        {deleting && <CoreSpinner loading={deleting} />}
                        <Button style={{ marginLeft: "15px" }} color="primary" onClick={() => { setOpenModal(false) }}>
                            Cancel
                        </Button>
                        { item.archived ?
                        <Button color="primary" style={{ marginRight: "15px" }} onClick={deleteTemp}>
                            Restore
                        </Button>
                        :
                        <Button color="error" style={{ marginRight: "15px" }} onClick={deleteTemp}>
                            Delete
                        </Button>
                        }
                    </Modal.Footer>
                </Modal>
            }
            <tr>
                <td>{item.title}</td>
                <td>{updated_at}
                </td>
                <td style={{marginLeft:'60px'}}>{created_at}
                </td>

                {isAdmin &&
                    <td>
                        <IconButton onClick={() => { setOpenModal(true) }} title="Restore" color='error'>
                            {
                                item.archived ?
                                <RestoreFromTrashIcon color='primary'/>
                                :
                                <Delete />
                            } 
                        </IconButton>
                    </td>
                }
                <td>
                    <IconButton title="Edit" style={{ fontSize: 17, marginRight: 10,color: "#4083ed" }} component={Link} to={{ pathname: "/builder", state: { id: item.id } }} color="primary">
                        {isAdmin ? <EditIcon /> : <PrintIcon />}
                    </IconButton>
                    {preview && preview.id === item.id ?
                        <IconButton title="Close" style={{ fontSize: 17, color: "red" }} onClick={() => { setPreview(null) }} color="primary">
                            <VisibilityOffIcon />
                        </IconButton>
                        :
                        <IconButton title="View" style={{ fontSize: 17, color: "#4083ed" }} onClick={() => { setPreview(item) }} color="primary">
                            <VisibilityIcon />
                        </IconButton>
                    }
                </td>
            </tr>
        </>
    );

};

export default TemplateListItem;