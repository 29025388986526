import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, Router } from 'react-router-dom';
import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import Layout from './Layout';
import Logout from './pages/includes/Logout';
import Login from './pages/Login';
import Print from './pages/includes/Print';
import ProtectedRoute from './components/Routes/ProtectedRoute';
import Forgot from './pages/includes/Forgot';
import history from "./utils/history";
import { useAuth0 } from "@auth0/auth0-react";
import { getUser } from 'actions/helpers';
import { roles, setCookie, isAuth } from 'actions/auth';
import LogoDark from "./assets/img/SDP_dark.png";
import { makeStyles } from '@mui/styles';
import Footer from "./components/Footer/Footer.js";
import PBCSDark from "./assets/img/pbcs_dark.png";
import PBCSLight from "./assets/img/pbcs_light.png";
import { ThemeContext } from './contexts/ThemeContext';
import FixedPlugin from "./components/FixedPlugin/FixedPlugin.js";
import CoreSpinner from './components/_core/CoreSpinner';
import { Button } from 'reactstrap';


const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: "5%",
    margin: "auto",
    width: "50%",
    padding: 10
  },
  logo: {
    margin: "3%",
    width: "140px",
    marginTop: '30px',
    marginBottom: '10px',
    marginLeft: '40px'
  },
  Wrapper: {
    position: "relative",
    background: 'rgba(108, 133, 215, 0.4)'
  },
  Text_SDP: {
    position: 'absolute',
    width: '30px',
    height: '16px',
    left: '183px',
    top: '38px',
    fontWeight: '500',
    fontSize: '25px',
    color: '#FFFFFF'
  },
  PBCS: {
    width: '304px',
    height: '30px',
    float: 'right',
    marginRight: '40px',
    marginTop: '20px',
    // fontFamily: 'Myriad Pro',
    fontStyle: 'normal',
    fontWeightt: '400',
    fontSize: '25px',
    color: '#2D76BC'
  },
  LoginButton: {
    padding: "14px 46px 14px 46px",
    fontSize: 16,
    marginTop: 0,
    width: '300px',
    height: '58.12px',
    left: '150px',
    background: 'white',
    color: "rgba(108, 133, 215, 1)",
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px'
  },
}));


const App = () => {
  const classes = useStyles();
  const theme = React.useContext(ThemeContext);
  const isDark = true;
  require("./assets/scss/black-dashboard-react.scss");

  const {
    getAccessTokenSilently,
    user,
    isAuthenticated,
    logout
  } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      }
    });

  const [loggedIn, setLoggedIn] = useState(undefined);
  const { isLoading, error } = useAuth0();
  const [loginError, setLoginError] = useState(undefined);

  useEffect(() => {
    async function getToken() {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        if (token) {
          setCookie("token", token);
        }
        const user = await getUser();
        if (user) {
          setLoggedIn(true);
        } else {
          setLoginError("Your account hasn't been activated yet.");
        }
      } else {
        if (!isLoading) {
          setLoggedIn(false);
        }
      }
    }
    getToken();
  }, [isLoading])

  // if (error) {
  //   return <div>Oops... {error.message}</div>;
  // }

  const redirect = async () => {
    if (loggedIn === false) {
      return "/login";
    }
    const appUser = await isAuth();
    switch (appUser.role) {
      case roles.superadmin:
        return "/companies";
      case roles.admin:
        return "/dashboard";
      default:
        return "/templates";
    }
  };

  if (loggedIn !== undefined && !isLoading) {
    return (
      <ThemeContextWrapper>
        <Router history={history}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route path="/print" component={Print} />
            <Route path="/forgot" component={Forgot} />
            <ProtectedRoute path="/" component={Layout} />
            <Redirect from="/" to={redirect()} />
          </Switch>
        </Router>
      </ThemeContextWrapper>
    )
  }
  return (
    <React.Fragment>
      <CoreSpinner loading={!loginError} />
      <div className={[classes.Wrapper, "wrapper"]}>
        <div className="main-panel" data="green" style={{ background: 'rgba(108, 133, 215, 0.2)' }}>
          <img className={classes.logo} src={LogoDark} alt="SDP Logo" />
          <div className={classes.Text_SDP}>Secure Document Publisher</div>
          <img className={classes.PBCS} src={isDark ? PBCSDark : PBCSLight} alt="PoweredByClinicalSquared" />
          {
            loginError &&
            <div className={classes.box}>
              <h4 style={{ color: "red", paddingLeft: "40%" }}>{loginError}</h4>
              <Button
                className={classes.LoginButton}
                variant="contained"
                onClick={() => window.location.reload()}
              >
                Retry
              </Button>
              <Button
                className={classes.LoginButton}
                variant="contained"
                onClick={() => logoutWithRedirect()}
              >
                Logout
              </Button>
            </div>
          }
          <div style={{ position: "absolute", bottom: 0, right: 0 }}>
            <Footer />
          </div>
        </div>
      </div>
      <FixedPlugin bgColor="green" />
    </React.Fragment>
  );
}

export default App;