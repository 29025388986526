import React from "react";
import { Container } from "reactstrap";
import { ThemeContext } from '../../contexts/ThemeContext';


function Footer() {
  const theme = React.useContext(ThemeContext);
  const isDark = theme.theme === "" ? true : false;

  return (
    <footer className="footer">
      <Container fluid>
        <div className="copyright">
          © {new Date().getFullYear()} powered by{" "}
          <a
            style={{color: !isDark ? "rgba(45, 62, 117, 1)" : "white"}}
            href="https://clinicalsquared.com"
          >
            Clinical Squared
          </a>{" "}
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
