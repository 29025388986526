import Resizer from "react-image-file-resizer";
import { isUserSuperadmin } from "./auth";
import { get } from "./api";


export const resizeFile = (file) =>
    new Promise((resolve) => {
        let type = file.type || "JPEG";
        if (type === "image/jpg" || type === "image/jpeg") {
            type = "JPEG";
        } else if (type === "image/png") {
            type = "PNG";
        } else {
            type = "JPEG";
        };

        Resizer.imageFileResizer(
            file,
            250,
            400,
            type,
            90,
            0,
            (uri) => {
                resolve(uri);
            },
            "file"
        );
    });


export const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};


export function convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
}

export async function getBusiness(id) {
    return await get("businesses/business" + (id ? `?business_id=${id}` : ""));
};

export async function getUser() {
    let user = null;
    if (localStorage.getItem("user")) {
        return JSON.parse(localStorage.getItem("user"));
    }
    try {
        user = await get("users/user");
        if (user) {
            const business = await getBusiness();
            user["business"] = business;
            localStorage.setItem("user", JSON.stringify(user));
        }
    } catch (error) {
        console.error(error);
    }
    return user;
};

export async function getUsers(business_id) {
    return await get("users" + (business_id ? `?business_id=${business_id}` : ""));
};

export async function listBusinesses() {
    if (isUserSuperadmin()) {
        return await get("businesses");
    };
};