import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import BusinessListItem from './includes/BusinessListItem';
import CoreSpinner from '../components/_core/CoreSpinner';
import {
  Card,
  CardBody,
  Table,
  Row,
  Col,
} from "reactstrap";
import AddIcon from '@mui/icons-material/Add';
import { listBusinesses } from '../actions/helpers';


const styles={
  Container:{
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
     marginLeft:'280px',
     marginRight:'60px',
     marginTop:'80px',
     marginBottom:'130px'
  },
  Button:{
    marginBottom: 15,
    background: 'rgba(108, 133, 215)',
    marginTop:'20px',
    paddingLeft: 30,
    left:'20px',
    fontSize:'15px',
    fontWeight:'bold'
  }
}

function Businesses() {
  const history = useHistory();
  const [businesses, setBusinesses] = useState(null);

  useEffect(() => {
    if (!businesses) {
      getBusinesses();
    }
  });

  const getBusinesses = async () => {
    const allBusinesses = await listBusinesses();
    setBusinesses(allBusinesses);
  };

  return (
    <>
      {!businesses && <CoreSpinner loading={!businesses} />}
      <div  className="content1" style={styles.Container}>
        <button className="btn btn-primary" style={styles.Button} onClick={() => history.push("/comp/create")}><AddIcon style={{fontSize: 22, marginBottom: 2}}/>&nbsp;&nbsp;Create Company</button>
        <Row>
          <Col md={"11"}>
            <Card>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th style={{fontWeight:'bold'}}>Name</th>
                      <th style={{fontWeight:'bold'}}>Abbreviated Name</th>
                      <th style={{fontWeight:'bold'}}>City</th>
                      <th style={{fontWeight:'bold'}}>State</th>
                      <th style={{fontWeight:'bold'}}>Country</th>
                      <th style={{fontWeight:'bold'}}>Website</th>
                      <th style={{fontWeight:'bold'}}>License</th>
                    </tr>
                  </thead>
                  {businesses &&
                    <tbody>
                      {businesses.map(business => <BusinessListItem business={business} key={business.id} />)}
                    </tbody>
                  }
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Businesses;
