import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { CardContent, CardHeader, Card } from '@mui/material';
import { Grid, Button, Checkbox } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { Link } from 'react-router-dom';
import { Edit } from '@mui/icons-material';
import { IconButton, Switch } from '@mui/material';
import TextBox from '../components/_core/Inputs/TextBox';
import CoreGridContainer from '../components/_core/CoreGridContainer';
import CoreGridItem from '../components/_core/CoreGridItem';
import { useHistory } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CoreSpinner from '../components/_core/CoreSpinner';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Row,
  Col,
} from "reactstrap";
import { isAuth, isUserAdmin, isUserSuperadmin } from "../actions/auth";
import { get, post, put, deleteItem } from '../actions/api';
import { resizeFile, convertBase64, getBusiness } from '../actions/helpers';
// import { Prompt } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


function Builder(props) {
  const TINY_API = process.env.REACT_APP_TINY_API;
  let isAdmin = isUserAdmin();

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex'
    },
    actionBar: {
      border: '1 solid #ddd'
    },
    upload: {
      display: 'none'
    },
    options: {
      minHeight: 20,
      width: 50
    },
    CardContent: {
      maxHeight: '100%',
      overflow: 'auto'
    },
    card: {
      height: '100%'
    },
    copied: {
      color: 'green',
      fontSize: '13px',
      marginTop: "4px",
      marginBottom: "-39px"
    },
    drawer: {
      width: 200
    },
    divider: {
      paddingTop: 10
    },
    viewer: {
      height: 'calc(160vh - 180px)',
      minHeight: 'calc(130vh - 222px)',
    },
    viewerButtons: {
      paddingLeft: "15px",
      paddingBottom: 10
    },
    file: {
      marginTop: "4px"
    }
  }));

  const history = useHistory();
  const classes = useStyles();
  const [viewer, setViewer] = useState('');
  const [fileError, setFileError] = useState('');
  const [imgError, setImgError] = useState(false);
  const [dataIndex, setDataIndex] = useState(0);
  const [file, setFile] = useState();
  const [data, setData] = useState(null);
  const [fields, setFields] = useState({});
  const [oFields, setOFields] = useState({});
  const [pFields, setPFields] = useState([]);
  const [cFields, setCFields] = useState([]);
  const [value, setValue] = useState('');
  const [visible, setVisible] = useState(false);
  const [notSaved, setNotSaved] = useState(false);
  const [editSection, setEditSection] = useState(false);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [savedToDb, setSavedToDb] = useState(true);
  const [saving, setSaving] = useState(false);
  const [previewOn, setPreviewOn] = useState(true);
  const [previewed, setPreviewed] = useState(false);
  const [indexes, setIndexes] = useState([]);
  const [autoPrev, setAutoPrev] = useState(false);
  const [dropdownOpenP, setDropdownOpenP] = useState(false);
  const [dropdownOpenC, setDropdownOpenC] = useState(false);
  const [stretch, setstretch] = useState(null);
  const environ = process.env.NODE_ENV;
  const [isDirty, setDirty] = useState(false);
  useEffect(() => {
    // Detecting browser closing
    window.onbeforeunload = isDirty
      ? () => isDirty
      : null;

    return () => {
      window.removeEventListener('beforeunload', () => {});
    };
  }, [isDirty]);

  // const routerPrompt = <Prompt when={isDirty} message={'Changes that you made may not be saved.'} />;

  if (environ === 'development') {
    window.localStorage.removeItem('refreshDev');
  } else {
    window.localStorage.removeItem('refreshProd');
  };

  const toggleP = () => setDropdownOpenP(prevState => !prevState);
  const toggleC = () => setDropdownOpenC(prevState => !prevState);

  useEffect(() => {
    if (!company) {
      getCompany();
    };
    getTemplateData();
  }, [company, isAdmin, history]);

  async function getTemplateData() {
    let templateId = null;
    try {
      templateId = props.location.state.id;
    } catch { return };
    if (templateId) {
      const template = await get(`templates/template/${templateId}`);
      setFields(template);
      setOFields(template);
      // updateViewer();
      setNotSaved(false);
      setSavedToDb(true);
    };
  };

  async function getCompany() {
    setLoading(true);
    const companyData = await getBusiness(isUserSuperadmin() && isAuth().business.id);
    const filterKeys = ["id"];
    const companyDataFiltered = Object.fromEntries(Object.entries(companyData).filter(([key]) => !filterKeys.includes(key)));
    setCompany(companyDataFiltered);
    const cKeys = [];
    Object.keys(companyDataFiltered).forEach(function (key) {
      return cKeys.push(key)
    });
    setCFields(cKeys);
    setData({ company: companyDataFiltered });
    setLoading(false);
  };

  const uploadFile = (evt) => {
    setLoading(true);
    setFile(evt.target.files[0]);
    const fileReader = new FileReader();
    try {
      fileReader.readAsText(evt.target.files[0], "UTF-8");
    } catch {
      setFile(null);
    };
    fileReader.onload = e => {
      try {
        const fileToJSON = JSON.parse(e.target.result);
        const pKeys = [];
        if (!Array.isArray(fileToJSON)) {
          setLoading(false);
          catchError("JSON must be an array of objects");
          return;
        };
        for (var obj in fileToJSON) {
          if ("logo" in fileToJSON[obj] || "Logo" in fileToJSON[obj]) {
            catchError("JSON Error: 'logo' is a reserved keyword");
            setLoading(false);
            return;
          };
        };
        fileToJSON.map((item, index) => {
          if (index === 0) {
            Object.keys(item).map((objKey, index) => {
              return pKeys.push(objKey)
            });
          };
          return (<></>);
        });

        setPFields(pKeys);
        setData({ ...data, patient: fileToJSON.reverse() });
        selectAll(fileToJSON.length);
        setPreviewOn(true);
        updateViewer();
        if (fileError) {
          setFileError(false);
        };
      } catch (e) {
        catchError('JSON Error: ' + e);
        setLoading(false);
      };
      setLoading(false);
    };
  };

  function handleChange(evt, editor) {
    if (editor) {
      setFields({ ...fields, template: editor })
      if (autoPrev) {
        updateViewer(editor);
      };
    } else if (evt) {
      setFields({ ...fields, [evt.target.name]: evt.target.value })
    } else {
      setFields({ ...fields, template: "" })
    };
  };

  async function handleLoad(e) {
    e.preventDefault();
    setLoading(true);
    setImgError(false);
    try {
      const file = e.target.files[0];
      if (file.size > 2000000) {
        return setImgError(true);
      };
      const resizedFile = await resizeFile(file);
      const base64 = await convertBase64(resizedFile);
      setFields({ ...fields, logo: base64 });
    } catch {
      document.getElementById("files").value = "";
    };
    setLoading(false);
    setNotSaved(true);
  };

  function handleOptionChange(e, type) {
    const newValue = e.target.value;
    setValue(newValue);
    if (type === "doc") {
      navigator.clipboard.writeText(`{${newValue}}`);
    } else if (newValue === "Logo") {
      navigator.clipboard.writeText(`{template logo 10}`);
    } else if (newValue === "logo") {
      navigator.clipboard.writeText(`{company.${newValue} 10}`);
    } else {
      navigator.clipboard.writeText(`{${type}${newValue}}`);
    };
    setVisible(type);
  };

  function autoPrevChange() {
    if (!autoPrev) {
      setPreviewOn(true);
      preview(true);
    };
    setAutoPrev(!autoPrev)
  };

  async function prevAndSave() {
    await preview(false);
    saveToDb();
  }

  async function preview(saved) {
    updateViewer();
    setNotSaved(saved);
  };

  function updateViewer(editor) {
    if (fields) {
      let currentData = editor || fields.template || "";
      for (var cKey in cFields) {
        if (cFields[cKey] !== "logo") {
        currentData = currentData.replaceAll(`{company.${cFields[cKey]}}`, data.company[cFields[cKey]]);
        };
      };
      for (var pKey in pFields) {
        currentData = currentData.replaceAll(`{${pFields[pKey]}}`, data.patient[dataIndex][pFields[pKey]]);
      };
      if (fields.logo) {
        const logoIndex = currentData.indexOf("{template logo");
        const startIndex = logoIndex + 15;
        let index = startIndex;
        let char;
        while (char !== "}" && (index - startIndex) < 4) {
          char = currentData[index];
          index++;
        };
        const size = currentData.slice(startIndex, index-1);
        currentData = currentData.replaceAll(`{template logo ${size}}`, `<img src=${fields.logo} width="${size}%" alt="" />`);
        currentData = currentData.replaceAll(`{template logo}`, `<img src=${fields.logo} alt="" />`);
      };
      if (data.company !== undefined) {
        const logoIndex2 = currentData.indexOf("{company.logo");
        const startIndex2 = logoIndex2 + 14;
        let index2 = startIndex2;
        let char2;
        while (char2 !== "}" && (index2 - startIndex2) < 4) {
          char2 = currentData[index2];
          index2++;
        };
        const size2 = currentData.slice(startIndex2, index2-1);
        currentData = currentData.replaceAll(`{company.logo ${size2}}`, `<img src=${data.company.logo} width="${size2}%" alt="" />`);
        currentData = currentData.replaceAll(`{company.logo}`, `<img src=${data.company.logo} alt="" />`);
      };
      setViewer(currentData);
    };
  };

  async function saveToDb() {
    if (file || isAdmin) {
      setSaving(true);

      if (!fields.created_at) {
        await post("/templates" + (isUserSuperadmin() ? `?business_id=${isAuth().business.id}` : ""), fields)
        setDirty(false);
        history.push("/templates");

      } else {
        const excluded = ["updated_at", "created_at", "business_id"];
        const filteredFields = {};
        for (let [key, value] of Object.entries(fields)) {
          if (!(excluded.includes(key)) && fields[key] !== oFields[key]) {
            filteredFields[key] = value;
          };
        };
        filteredFields["id"] = fields.id;
        await put("/templates", filteredFields)
        setSaving(false);
        setSavedToDb(true);
        setDirty(false);
      }
    };
  };

  function catchError(message) {
    setFileError(message);
    setNotSaved(false);
    setData(null);
    setFile(null);
    document.getElementById("btn-upload").value = "";
  };

  function selectAll(len) {
    setIndexes([]);
    if (len) {
      for (var i = 0; i < len; i++) {
        setIndexes((currentIndexes) => [...currentIndexes, i]);
      };
    } else {
      if (data) {
        data.patient.map((item, key) => {
          setIndexes((currentIndexes) => [...currentIndexes, key]);
          return null;
        })
      };
    };
  };

  const Expire = props => {
    useEffect(() => {
      setTimeout(() => {
        setVisible(false);
      }, props.delay);
    }, [props.delay]);

    return visible ? <div>{props.children}</div> : <div />;
  };


  return (
    <div className="content">
      <h3 style={{ marginBottom: 12 }}>{fields.title || "New Template"}</h3>
      {!savedToDb || notSaved ?
        <button style={{ height: 39, marginTop: 5, float: "right" }} className="btn btn-primary" onClick={prevAndSave}>SAVE</button>
        :
        <></>
      }
      <p style={{ marginBottom: 25 }}>{fields.description || "-"}</p>

      {/* <h3 style={{ marginBottom: 12 }}>{fields.title || "New Template"}</h3> */}

      {/* <div  style={{background:'white',display:'flex',justifyContent:'space-between'}}>
      <div  style={styles.Wrapper}>
                    <Col md='5'>
                          <Dropdown style={{ marginLeft: 0 }} value={visible === "company." ? value : ""} isOpen={dropdownOpenC} toggle={toggleC}>
                            <DropdownToggle style={{ padding: "10px 18px 10px 18px",background:'#a74af7' }}>Demographic Keys&nbsp;&nbsp;▼</DropdownToggle>
                            <DropdownMenu
                              modifiers={{
                                setMaxHeight: {
                                  enabled: true,
                                  order: 890,
                                  fn: (data) => {
                                    return {
                                      ...data,
                                      styles: {
                                        ...data.styles,
                                        overflow: 'auto',
                                        maxHeight: 200,
                                      },
                                    };
                                  },
                                },
                              }}
                            >
                              {
                                cFields.map((item, key) =>
                                  <DropdownItem onClick={e => handleOptionChange(e, "company.")} value={item} key={key}>{item}</DropdownItem>
                                )
                              }
                              {
                                fields.logo &&
                                <DropdownItem onClick={e => handleOptionChange(e, "company.")} value={"Logo"} key={"Logo"}>{"Logo"}</DropdownItem>
                              }
                            </DropdownMenu>
                          </Dropdown>
                        </Col>

                        <Col md='4'>
                                        <label for="btn-upload">
                                                <input
                                                  id="btn-upload"
                                                  name="btn-upload"
                                                  className={classes.upload}
                                                  type="file"
                                                  accept="application/json"
                                                  onChange={uploadFile}
                                            />
                                            <Button
                                            style={styles.choose_json}
                                              className="btn-choose"
                                              variant="outlined"
                                              component="span" >
                                              Choose Json
                                            </Button>
                                          </label>
                      </Col>
                        </div>

                        <div style={{marginTop:'-20px'}}> */}
                          
      {fields && isAdmin ?
        <>
          {!editSection ?
            <>
              <IconButton title="Edit" color="primary" style={{ fontSize: 16, marginBottom: 10 }} onClick={() => { setEditSection(true) }}>
                <Edit />&nbsp;Title / Description / Logo
              </IconButton>
            </>
            :
            <Row style={{marginLeft: 6}}>
              <IconButton title="Edit" color="primary" style={{ fontSize: 16, marginBottom: 10 }} onClick={() => { setEditSection(false) }}>
                <>X&nbsp;Close</>
              </IconButton>
              <CoreGridContainer justifyContent='flex-start'>
                <CoreGridItem xs={6} md={4}>
                  <TextBox
                    required
                    id="title"
                    label="Title"
                    name="title"
                    autoFocus
                    onChange={(e) => { handleChange(e); setNotSaved(true) }}
                    value={fields.title}
                    inputProps={{ maxLength: 25 }}
                  />
                </CoreGridItem>
              </CoreGridContainer>
              <CoreGridContainer style={{ marginBottom: 10 }} justifyContent='flex-start'>
                <CoreGridItem xs={8} md={8}>
                  <TextBox
                    required
                    id="description"
                    label="Description"
                    name="description"
                    onChange={(e) => { handleChange(e); setNotSaved(true) }}
                    value={fields.description}
                    inputProps={{ maxLength: 75 }}
                  />
                </CoreGridItem>
              </CoreGridContainer>
              <form>
                <label htmlFor='files' className="btn">Choose a Logo</label>
                <input accept="image/png, image/jpeg" id="files" type="file" onChange={handleLoad} style={{ visibility: "hidden", marginRight: -220 }} />
              </form>
              {fields.logo &&
                <img style={{ marginLeft: 30 }} width="5%" src={fields.logo} alt="Logo" />
              }
              {imgError && <p style={{ marginLeft: 12, marginTop: 11, color: "red" }}>Image file is too big!</p>}
            </Row>
          }
        </>
        :
        null
      }

      {/* </div>
      <div style={{float:'right',margin:'20px'}}>
      {!savedToDb || notSaved ?
        <button style={{ height: 39, marginTop: 5, float: "right" }} className="btn btn-primary" onClick={prevAndSave}>SAVE</button>
        :
        <></>
      }
</div> */}

      {/* </div> */}
      {saving && <CoreSpinner loading={saving} />}
      {!loading ?
        <Grid container spacing={5} alignItems="center" justifyContent="space-between">
          <Grid item xs={12} className={classes.actionBar}>
            {fileError &&
              <div className="alert alert-danger" style={{ marginTop: 10 }} role="alert">
                {fileError}
              </div>
            }
            <Grid style={{ marginTop: 25 }} container spacing={2} alignItems="stretch">
                <Grid item xs={12} md={4}>
                <label htmlFor="btn-upload">
                  <input
                    id="btn-upload"
                    name="btn-upload"
                    className={classes.upload}
                    type="file"
                    accept="application/json"
                    onChange={uploadFile}
                  />
                  <Button
                    className="btn-choose"
                    variant="outlined"
                    component="span" >
                    Choose JSON File
                  </Button>
                </label>
              </Grid>
              {file &&
                <Grid className={classes.file} style={{ marginTop: 7 }} item xs={4} sm={4} md={4} lg={4}>
                  File: {file?.name}
                </Grid>
              }
            </Grid>
          </Grid>
          {isAdmin &&
            <Grid item xs={12} md={stretch ? 12 : 6} className={classes.viewer}>
            <Card className={classes.card}>
            { !stretch ?  <IconButton style={{float:'right', margin: 15}} onClick={()=>{ setstretch(1)}}><OpenInFullIcon style={{fontSize: 25}}/></IconButton>  : null }
            { stretch ?  <IconButton style={{float:'right', margin: 15}} onClick={()=>{ setstretch(0)}}><CloseFullscreenIcon style={{fontSize: 30}}/></IconButton>  : null }
              <div style={{ display: "flex", flexDirection: "row" }} >

                <CardHeader title="Builder" />
                {
                  <div style={{ margin: "auto", marginTop: 18 }}>
                    <Row>
                      <FormControlLabel
                        style={{ marginRight: 2 }}
                        control={
                          <Switch
                            checked={autoPrev}
                            onChange={() => { autoPrevChange() }}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                      />
                      <p style={{ color: "black", marginTop: 8 }}>Auto Preview</p>
                    </Row>
                  </div>
                }
              </div>
              <CardContent item xs={12}>
                <Container fluid>
                  <Row>
              { data &&
                        <Col>
                          <Dropdown style={{ marginLeft: 0 }} value={visible === "company." ? value : ""} isOpen={dropdownOpenC} toggle={toggleC}>
                            <DropdownToggle style={{ padding: "10px 18px 10px 18px" }}>Demographic Keys&nbsp;<ExpandMoreIcon style={{fontSize: 26, marginBottom: 2}}/></DropdownToggle>
                            <DropdownMenu
                              modifiers={{
                                setMaxHeight: {
                                  enabled: true,
                                  order: 890,
                                  fn: (data) => {
                                    return {
                                      ...data,
                                      styles: {
                                        ...data.styles,
                                        overflow: 'auto',
                                        maxHeight: 200,
                                      },
                                    };
                                  },
                                },
                              }}
                            >
                              {
                                cFields.map((item, key) =>
                                 item !== "logo" ?
                                  <DropdownItem onClick={e => handleOptionChange(e, "company.")} value={item} key={key}>{item}</DropdownItem>
                                  :
                                  <DropdownItem onClick={e => handleOptionChange(e, "company.")} value={"logo"} key={"logo"}>{"company logo"}</DropdownItem>
                                )
                              }
                              {
                                fields.logo &&
                                <DropdownItem onClick={e => handleOptionChange(e, "company.")} value={"Logo"} key={"Logo"}>{"template logo"}</DropdownItem>
                              }
                            </DropdownMenu>
                          </Dropdown>
                        </Col>
                          }
                                     {file &&
                        <Col>
                          <Dropdown value={visible === "doc" ? value : ""} isOpen={dropdownOpenP} toggle={toggleP}>
                          <DropdownToggle style={{ padding: "10px 24px 10px 24px" }}>Document Keys&nbsp;<ExpandMoreIcon style={{fontSize: 26, marginBottom: 2}}/></DropdownToggle>
                            <DropdownMenu
                              modifiers={{
                                setMaxHeight: {
                                  enabled: true,
                                  order: 890,
                                  fn: (data) => {
                                    return {
                                      ...data,
                                      styles: {
                                        ...data.styles,
                                        overflow: 'auto',
                                        maxHeight: 200,
                                      },
                                    };
                                  },
                                },
                              }}
                            >
                              {
                                pFields.map((item, key) =>
                                  <DropdownItem value={item} key={key} onClick={e => handleOptionChange(e, "doc")}>{item}</DropdownItem>
                                )
                              }
                            </DropdownMenu>
                          </Dropdown>
                        </Col>
                      }
                      
                      {
                        <Col>
                          {!autoPrev &&
                            <>
                              {!previewOn || !previewed ?
                                <button disabled={false} style={{ padding: "10px 18px 10px 18px", marginTop: 4, marginLeft: 0 }} className="btn btn-primary" onClick={() => { preview(true); setPreviewOn(true); setPreviewed(true) }}>PREVIEW</button>
                                :
                                null
                              }
                              {
                                previewed &&
                                <button disabled={true} style={{ padding: "10px 18px 10px 18px", marginTop: 4, marginRight: 0 }} onClick={() => { }} className="btn btn-primary">PREVIEW</button>
                              }
                            </>
                          }
                        </Col>
                      }
                    </Row>

                    <Row>
                      <Col>
                        {visible === "doc" &&
                          <Expire delay="2000">
                            <p className={classes.copied}>Copied!</p>
                          </Expire>
                        }
                      </Col>
                      <Col>
                        {visible === "company." &&
                          <Expire delay="2000">
                            <p className={classes.copied}>Copied!</p>
                          </Expire>
                        }
                      </Col>
                      <Col></Col>
                    </Row>
                  </Container>
                </CardContent>
                <CardContent className={classes.CardContent}>
                  <Grid container spacing={2} direction="column">
                    <Grid item xs={12}>
                      <Editor
                        // disabled={!file}
                        // initialValue={fields.template || ""}
                        editorState={fields.template}
                        apiKey={TINY_API}
                        init={{
                          height: "1000px",
                          lineheight_formats: "1pt 2pt 3pt 4pt 5pt 6pt 7pt 8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 36pt",
                          fontsize_formats: "6pt 7pt 8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 36pt",
                          invalid_elements: 'script[language|type|src]',
                          valid_children: '+body[style]',
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                            'code',
                            'image',
                            'lineheight'
                          ],
                          toolbar: 'undo redo | styleselect fontselect fontsizeselect | forecolor backcolor | code | image |' +
                            'bold italic underline | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | lineheightselect | ' +
                            'removeformat | help',
                          content_style: 'body { font-family: Times New Roman }',
                          contextmenu: "paste | link image inserttable"
                        }}
                        value={fields.template}
                        onEditorChange={(editor) => { setDirty(true); handleChange(false, editor); setNotSaved(true); setPreviewed(false); }}
                        onEditorStateChange={(editor) => { handleChange(false, editor); }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          }
          {
            <Grid item xs={12} md={6} style={{ display: stretch ? 'none':'block'}} className={classes.viewer}>
              <Card className={classes.card} elevation={2}>
                <CardHeader title="Preview" />
                {viewer && file && !loading ?
                  <Grid item xs={12} style={{marginTop: 22}} className={classes.viewerButtons}>
                    <button className="btn btn-secondary" style={{ padding: "8px 16px 8px 16px", color: "white" }} disabled={dataIndex <= 0 && true} onClick={() => { setDataIndex(dataIndex - 1); preview(notSaved); }}>&lt; </button>
                    <button className="btn btn-secondary" disabled={dataIndex >= data.patient.length - 1 && true} style={{ marginLeft: "8px", padding: "8px 16px 8px 16px" }} onClick={() => { setDataIndex(dataIndex + 1); preview(notSaved); }}>&gt; </button>

                    <Checkbox checked={indexes.indexOf(dataIndex) > -1} onClick={() => {
                      if (indexes.indexOf(dataIndex) > -1) {
                        setIndexes((currentIndexes) => currentIndexes.filter(function (index) {
                          return index !== dataIndex;
                        }))
                      } else {
                        setIndexes((currentIndexes) => [...currentIndexes, dataIndex]);
                      };
                    }} />
                    <button style={{ marginLeft: "8px", padding: "10px 18px 10px 18px", color: "white" }} disabled={indexes.length === data.patient.length} onClick={() => { selectAll() }} className="btn btn-secondary">Select all</button>
                    <button style={{ marginLeft: "8px", padding: "10px 18px 10px 18px", color: "white" }} disabled={indexes.length < 1} onClick={() => { setIndexes([]) }} className="btn btn-secondary">Deselect all</button>
                    
                    {file && fields &&
                                        <>
                    { !isDirty ? 
                      <Button style={{ marginLeft: "8px", padding: "7px 30px 7px 30px", backgroundColor: "#d34fda", color: "white", fontFamily: 'sans-serif', fontWeight: "700" }} disabled={indexes.length < 1} component={Link} to={{ pathname: "/print", state: { data, template: fields, indexes } }} className="">PRINT...</Button>
                    :
                    <p style={{fontSize: 12, color: "orange", marginBottom: -20, marginTop: 3}}>Save to print</p>
                    }
                    </>
                  }
                  </Grid>
                  :
                  null
                }
                <CardContent>
                <iframe title="Preview" srcDoc={viewer} style={{ width: "100%", height: "950px", marginTop: !file ? 80 : 90, border: "1px solid rgb(110, 110, 110, 0.4)"}} />
                </CardContent>
              </Card>
            </Grid>
          }
        </Grid>
        :
        <CoreSpinner loading={loading} />
      }
    </div>
  );
}

export default Builder;
