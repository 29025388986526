import cookie from 'js-cookie';
import { getBusiness, getUser } from './helpers';


export const roles = {
  superadmin: "superadmin",
  admin: "admin",
  user: "user"
}

export const isAuth = () => {
  if (process.browser) {
      if (localStorage.getItem('user')) {
        return JSON.parse(localStorage.getItem('user'));
      };
  };
  return null;
};

export const isUserAdmin = () => {
  if (localStorage.getItem('user')) {
    const role = JSON.parse(localStorage.getItem('user')).role;
    if (role === roles.admin || role === roles.superadmin) {
      return true;
    };
  }
  return false;
};

export const isUserSuperadmin = () => {
  if (localStorage.getItem('user')) {
    const role = JSON.parse(localStorage.getItem('user')).role;
    if (role === roles.superadmin) {
      return true;
    };
  }
  return false;
};

export const changeBusiness = async (id) => {
    const business = await getBusiness(id);
    const user = await getUser();
    user.business = business;
    localStorage.setItem("user", JSON.stringify(user));
};

export const logout = async () => {
  removeCookie('token');
  localStorage.removeItem("user");
};

export const setCookie = (key, value) => {
  if (process.browser) {
    const options = {};
    if (process.env.NODE_ENV === "production") {
      options["secure"] = true;
    };
    cookie.set(key, value, options);
  }
};

export const getCookie = key => {
  if (process.browser) {
    return cookie.get(key);
  }
};

export const removeCookie = key => {
  if (process.browser) {
    cookie.remove(key, {
      expires: 1
    });
  }
};