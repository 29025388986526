import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { isUserAdmin, isAuth, isUserSuperadmin } from '../actions/auth';
import TemplateListItem from './includes/TemplateListItem';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import CoreSpinner from '../components/_core/CoreSpinner';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
} from "reactstrap";
import { get, post, put, deleteItem } from '../actions/api';
import TemplateGridItem from './includes/TemplateGrid';


function Templates() {
  const history = useHistory();
  const isAdmin = isUserAdmin();
  const [templates, setTemplates] = useState(null);
  const [preview, setPreview] = useState(null);
  const [Grid, setGrid] = useState(false);

  const styles = {

    Container: {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      marginLeft: '280px',
      marginRight: '60px',
      marginTop: '80px',
      marginBottom: '130px'
    },

    New_Template_Button: {
      width: '70%',
      height: '56px',
      left: '225px',
      top: '86px',
      background: 'rgba(108, 133, 215, 1)',
      boxShadow: '0px 4px 4px rgba(97, 97, 97, 0.2)',
      borderRadius: '5px',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '16px',
    },
    List_View_Button: {
      width: '70%',
      height: '56px',
      left: '225px',
      top: '106px',
      background: 'rgba(108, 133, 215, 1)',
      boxShadow: '0px 4px 4px rgba(97, 97, 97, 0.2)',
      borderRadius: '5px',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '16px',
    },
    recents_text: {
      display: preview ? 'none' : 'flex',
      position: preview ? '' : 'absolute',
      width: '173px',
      height: '16px',
      left: '302px',
      top: '325px',
      // fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '18px',
      zIndex: '1000',
    },
    custom_text: {
      display: preview ? 'none' : 'flex',
      position: preview ? '' : 'absolute',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '20px',
      marginLeft: '0px'
    },
    grid_view_button: {
      marginLeft: '30px',
      background: 'rgba(108, 133, 215, 1)'
    },
    New_Template_Button1: {
      marginBottom: 15,
      marginLeft: '30px',
      background: 'rgba(108, 133, 215, 1)'
    },
    iframe_style: {
      width: "100%",
      height: "950px",
      backgroundColor: "white",
      border: "0px solid grey"
    }


  }
  useEffect(() => {
    if (!templates) {
      getTemplates();
    }
  });

  function changeView() {
    setGrid(!Grid);
    localStorage.setItem("grid", !Grid)
  };

  async function getTemplates() {
    const allTemps = await get("templates" + (isUserSuperadmin() ? `?business_id=${isAuth().business.id}` : ""));
    let view = false;
    if (JSON.parse(localStorage.getItem('grid')) !== undefined) {
      view = JSON.parse(localStorage.getItem('grid'));
    };
    setGrid(view);
    if (!allTemps) {
      setTemplates([]);
    } else {
      setTemplates(allTemps);
    };
  };

  return (
    <>
      <CoreSpinner loading={!templates} />
      <div className="content1" style={styles.Container}>
        <Row style={{ padding: '20px' }}>
          <div>
            {isAdmin && <button className="btn btn-primary" style={{ marginLeft: 20, paddingLeft: 35, fontSize: 15 }} onClick={() => history.push("/builder")}><AddOutlinedIcon style={{ marginBottom: 2, fontSize: 22 }} />&nbsp;&nbsp;New Template</button>}
            {!Grid ?
              <button className="btn btn-primary" style={styles.grid_view_button} onClick={changeView}><ViewModuleIcon style={{ marginBottom: 2 }} />&nbsp;&nbsp;Grid View</button>
              :
              <button className="btn btn-primary" style={styles.grid_view_button} onClick={changeView}><ListOutlinedIcon style={{ marginBottom: 2 }} />&nbsp;&nbsp;List View</button>
            }
          </div>
        </Row>

        <Row style={{ display: !Grid ? 'none' : 'block', paddingTop: '30px', marginRight: "100px" }}>
          <Col md={"12"} style={{ display: preview ? 'none' : 'block', marginLeft: "30px" }}>
            <Card>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                  </thead>
                  {templates &&
                    <tbody style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {templates.map(template => !template.archived && <TemplateGridItem item={template} preview={preview} setPreview={setPreview} key={template.id} />)}
                      {templates && templates.length < 1 ?
                        <tr>
                          <td style={{ padding: 10 }}>No templates</td>
                        </tr>
                        :
                        null
                      }
                    </tbody>
                  }
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row style={{ display: !Grid ? 'block' : 'none' }}>

          <Col md={"12"} style={{ display: preview ? 'none' : 'block' }}>
            <Card>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th >Title</th>
                      <th>Lastest Updated At</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  {templates &&
                    <tbody>
                      {templates.map(template => !template.archived && <TemplateListItem item={template} preview={preview} setPreview={setPreview} key={template.id} />)}
                      {templates && templates.length < 1 ?
                        <tr>
                          <td style={{ padding: 10 }}>No templates</td>
                        </tr>
                        :
                        null
                      }
                    </tbody>
                  }
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {isUserSuperadmin() &&
          <>
            <Row style={{ display: !Grid ? 'none' : 'block', paddingTop: '30px', marginRight: "100px" }}>
              <Col md={"12"} style={{ display: preview ? 'none' : 'block', marginLeft: "30px" }}>
                <Card>
                  <CardHeader>
                    <p style={styles.custom_text}>Archived</p>
                  </CardHeader>
                  <CardBody style={{ paddingTop: 40 }}>
                    <Table className="tablesorter" responsive>
                      <thead className="text-primary">
                      </thead>
                      {templates &&
                        <tbody style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {templates.map(template => template.archived && <TemplateGridItem item={template} preview={preview} setPreview={setPreview} key={template.id} />)}
                          {templates && templates.length < 1 ?
                            <tr>
                              <td style={{ padding: 10 }}>No templates</td>
                            </tr>
                            :
                            null
                          }
                        </tbody>
                      }
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row style={{ display: !Grid ? 'block' : 'none' }}>
              <Col md={"12"} style={{ display: preview ? 'none' : 'block' }}>
                <Card>
                  <CardHeader>
                    <p style={styles.custom_text}>Archived</p>
                  </CardHeader>
                  <CardBody style={{ paddingTop: 40 }}>
                    <Table className="tablesorter" responsive>
                      <thead className="text-primary">
                        <tr>
                          <th >Title</th>
                          <th>Lastest Updated At</th>
                          <th>Created At</th>
                        </tr>
                      </thead>
                      {templates &&
                        <tbody>
                          {templates.map(template => template.archived && <TemplateListItem item={template} preview={preview} setPreview={setPreview} key={template.id} />)}
                          {templates && templates.length < 1 ?
                            <tr>
                              <td style={{ padding: 10 }}>No templates</td>
                            </tr>
                            :
                            null
                          }
                        </tbody>
                      }
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        }

        <Row style={{ marginTop: !Grid ? '-90px' : '-180px' }}>
          {preview &&
            <Col md="12">
              <Card>
                <CardHeader>
                  <h4 style={{ float: 'right' }}>
                    {/* {preview.title} */}
                    <IconButton style={{}} onClick={() => { setPreview(null) }} color='primary'>
                      <Close />
                    </IconButton>
                  </h4>
                </CardHeader>
                <CardBody>
                  <iframe title="Preview" srcDoc={preview.template} style={styles.iframe_style} />
                </CardBody>
              </Card>
            </Col>
          }
        </Row>
      </div>
    </>
  );
}

export default Templates;