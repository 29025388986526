import Dashboard from "./pages/Dashboard.js";
import Builder from "./pages/Builder.js";
import Templates from "./pages/Templates.js";
import Users from "./pages/Users.js";
import { HiTemplate } from 'react-icons/hi';
import { FaThList } from 'react-icons/fa';
import { AiFillPieChart } from 'react-icons/ai';
import { HiUsers } from 'react-icons/hi';
import { FaBuilding, FaList } from 'react-icons/fa';
import EditUser from "./pages/includes/EditUser.js";
import EditCompany from "./pages/includes/EditCompany.js";
import CreateUser from "./pages/includes/CreateUser.js";
import Company from "./pages/Company.js";
import CreateBusiness from "./pages/includes/CreateBusiness.js";
import Businesses from "./pages/Businesses.js";


var routes = [
  {
    path: "/companies",
    name: "COMPANIES",
    icon: <FaList fontSize={22} />,
    component: Businesses,
    roles: ["superadmin"],
    license: ["standard", "blockchain"]
  },
  {
    path: "/dashboard",
    name: "DASHBOARD",
    icon: <AiFillPieChart fontSize={22} />,
    component: Dashboard,
    roles: ["admin", "superadmin"],
    license: ["standard", "blockchain"]
  },
  {
    path: "/builder",
    name: "TEMPLATE BUILDER",
    icon: <HiTemplate fontSize={27} />,
    component: Builder,
    roles: ["admin", "superadmin"],
    license: ["standard", "blockchain"]
  },
  {
    path: "/templates",
    name: "TEMPLATES",
    icon: <FaThList fontSize={20} />,
    component: Templates,
    roles: ["admin", "user", "superadmin"],
    license: ["standard", "blockchain"]
  },
  // {
  //   path: "/file-storage",
  //   name: "FILE STORAGE",
  //   icon: <GiFiles fontSize={28} />,
  //   component: FileStorage,
  //   roles: ["admin", "user"],
  //   license: ["standard", "blockchain", "superadmin"]
  // },
  // {
  //   path: "/nft",
  //   name: "NFTs",
  //   icon: <GiWallet fontSize={27} />,
  //   component: Todo,
  //   roles: ["admin", "user", "superadmin"],
  //   license: ["blockchain"]
  // },
  {
    path: "/users",
    name: "USERS",
    icon: <HiUsers fontSize={27} />,
    component: Users,
    roles: ["admin", "superadmin", "user"],
    license: ["standard", "blockchain"]
  },
  {
    path: "/company",
    name: "COMPANY",
    icon: <FaBuilding fontSize={25} />,
    component: Company,
    roles: ["admin", "user", "superadmin"],
    license: ["standard", "blockchain"]
  },
  {
    path: "/user/create",
    name: "CREATE USER",
    component: CreateUser,
  },
  {
    path: "/comp/create",
    name: "CREATE COMPANY",
    component: CreateBusiness,
  },
  {
    path: "/user/:id",
    name: "",
    component: EditUser,
  },
  {
    path: "/edit_company",
    name: "EDIT COMPANY",
    component: EditCompany,
  }
];
export default routes;
