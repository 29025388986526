import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { Login, Check } from '@mui/icons-material';
import { changeBusiness, isAuth } from '../../actions/auth';
import CoreSpinner from '../../components/_core/CoreSpinner';


const BusinessListItem = ({ business }) => {
    const [loading, setLoading] = useState(false);
    const isComp = isAuth().business.id === business.id;

    async function openBusiness() {
        setLoading(true);
        await changeBusiness(business.id);
        window.location.reload();
    };

    return (
        <>
            <CoreSpinner loading={loading} />
            {/* <Modal style={{ marginTop: 100 }} show={modal}>
                <Modal.Header>
                    <Modal.Title style={{ color: "red", marginBottom: 7 }}>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ marginBottom: -15 }}>
                    Are you sure you want to eliminate business:<br />
                    <strong>{user.firstName + " " + user.lastName}</strong>?<br /> <br />
                </Modal.Body>
                <Modal.Footer style={{ marginBottom: 25 }}>
                    {deleting && <CoreSpinner loading={deleting} />}
                    <Button style={{ marginLeft: "15px" }} color="primary" onClick={() => { setModal(false) }}>
                        Cancel
                    </Button>
                    <Button color="error" style={{ marginRight: "15px" }} onClick={deleteItem}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal style={{ marginTop: 100 }} show={errorModal}>
                <Modal.Header>
                    <Modal.Title style={{ color: "red", marginTop: -10 }}></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    You can't delete your own account!
                </Modal.Body>
                <Modal.Footer style={{ marginBottom: 25 }}>
                    <Button style={{ marginLeft: "15px" }} color="primary" onClick={() => { setErrorModal(false) }}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal> */}
            <tr>
                <td>{business.business_name}</td>
                <td>{business.abbrev}</td>
                {/* <td style={{ fontSize: 11, textAlign: "center" }}>{business.city}</td> */}
                <td>{business.city}</td>
                <td>{business.state}</td>
                <td>{business.country}</td>
                <td><a target="_blank" rel="noreferrer" href={business.website ?? ''}>{business.website ? business.website : "-"}</a></td>
                <td>{business.license}</td>
                {/* <td style={{ textAlign: "center", textTransform: "capitalize" }}>Tue,8/09/2022, 3:47:26 pm</td> */}
                {/* <td> */}
                    {/* <IconButton onClick={() => { openModal() }} title="Delete" color='error'> */}
                        {/* <Delete /> */}
                        {/* <VisibilityOffIcon/> */}
                    {/* </IconButton> */}
                {/* </td> */}
                <td>
                    { !isComp ?
                    <IconButton title="Go" style={{ fontSize: 17 }} onClick={openBusiness} color="primary">
                        <Login/>
                    </IconButton>
                    :
                    <Check style={{color: "green", marginLeft: 13}}/>
}
                </td>               
            </tr>
        </>
    );
};

export default BusinessListItem;