import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer';
import { useAuth0 } from "@auth0/auth0-react";


const SESSION_IDLE_MINUTES = 15;

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const history = useHistory();
  const {
    isAuthenticated
  } = useAuth0();

  async function handleOnIdle() {
      history.push("/logout");
  }

  useIdleTimer({
    timeout: 1000 * 60 * SESSION_IDLE_MINUTES,
    onIdle: handleOnIdle,
    debounce: 500
  })

  return (
    <>
      <Route
        {...restOfProps}
        render={(props) =>
          isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
        }
      />
    </>
  );
}

export default ProtectedRoute;
