import * as React from 'react';
import { ThemeContext } from '../../../../contexts/ThemeContext';
import TextField from '@mui/material/TextField';
import { makeStyles } from "@material-ui/core/styles";


const TextBox = (props) => {
  const theme = React.useContext(ThemeContext);
  const isDark = theme.theme === "" ? true : false;

  const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: isDark ? "white" : "black"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "purple"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "purple"
      },
      "& .MuiOutlinedInput-input": {
        color: "purple"
      },
      // ".MuiInputBase-root" : {
      //   backgroundColor: "green"
      // }
      // "&:hover .MuiOutlinedInput-input": {
      //   color: "red"
      // },
      // "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      //   color: "purple"
      // },
      // "& .MuiInputLabel-outlined": {
      //   color: "green"
      // },
      // "&:hover .MuiInputLabel-outlined": {
      //   color: "red"
      // },
      // "& .MuiInputLabel-outlined.Mui-focused": {
      //   color: "purple"
      // }
    }
  });
  const classes = useStyles();

  return (
    <TextField
      variant="outlined"
      margin="normal"
      size="small"
      color="secondary"
      fullWidth
      className={classes.root}
      InputLabelProps={{
        style: { color: isDark ? 'purple' :'#000000' },
      }}
      {...props}
    />
  );
}

export default TextBox;