import axios from "axios";
import { getCookie } from "./auth";
import { useAuth0 } from "@auth0/auth0-react";
import { setCookie } from 'actions/auth';


const prefix = 'api';
const GET = "GET";
const POST = "POST";
const PUT = "PUT";
const DEL = "DELETE";

export async function get(path) {
    return await Request(GET, path);
};

export async function put(path, data) {
    return await Request(PUT, path, data);
};

export async function post(path, data) {
    return await Request(POST, path, data);
};

export async function deleteItem(path) {
    return await Request(DEL, path);
};


export async function Request(method, path, body = null) {
    // const {
    //     getAccessTokenSilently,
    //     loginWithPopup,
    //     getAccessTokenWithPopup,
    // } = useAuth0();

    const fullpath = prefix + path;
    let response;
    const headers = {
        headers: {
            Accept: 'application/json',
            ContentType: 'application/json',
            Authorization: 'Bearer ' + getCookie("token")
        }
    }
    switch (method) {
        case GET:
            response = await axios.get("/" + prefix + "/" + path, headers);
            break;
        case POST:
            response = await axios.post("/" + fullpath, body, headers);
            break;
        case PUT:
            response = await axios.put("/" + fullpath, body, headers);
            break;
        case DEL:
            response = await axios.delete("/" + fullpath, headers);
            break;
        default:
            return 400;
    }
    if (response.status === 401) {
        // await loginWithPopup();
        // await getAccessTokenWithPopup();
        // const token = await getAccessTokenSilently();
        // if (token) {
        //     setCookie("token", token);
        // }
    };
    // TODO: create notification on any other error
    if (method === GET) {
        return response.data;
    }
    return response.status;
}

export default Request;