import React from "react";
import { Link, useLocation } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// reactstrap components
import { Nav, NavLink as ReactstrapNavLink } from "reactstrap";
import { isAuth } from "../../actions/auth";
import { useHistory } from "react-router-dom";
import LogoDark from "../../assets/img/SDP_dark.png";
import NavLink  from 'react-bootstrap/Nav';


var ps;

function Sidebar(props) {
  const history = useHistory();
  const location = useLocation();
  const sidebarRef = React.useRef(null);
  const user = isAuth();
  const business = user.business;
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  const { routes, logo } = props;
  let logoImg = null;
  let logoText = null;
  if (logo !== undefined) {
    if (logo.outterLink !== undefined) {
      logoImg = (
        <a
          href={logo.outterLink}
          className="simple-text logo-mini"
          // target="_blank"
          // onClick={props.toggleSidebar}
          // onClick={() => history.push(`/company`)}
        >
          <div className="logo-img">
            <img style={{backgroundColor: "transparent"}} src={business.logo || LogoDark} alt="logo" />
          </div>
        </a>
      );
      logoText = (
        <a
          href={logo.outterLink}
          className="simple-text logo-normal"
          // onClick={() => history.push(`/company`)}
          >
          {logo.text}
        </a>
      );
    } else {
      logoImg = (
        <Link
          to={logo.innerLink}
          className="simple-text logo-mini"
          // onClick={() => history.push(`/company`)}
          >
          <div className="logo-img">
            <img style={{backgroundColor: "transparent"}} src={business.logo || LogoDark} alt="logo" />
          </div>
        </Link>
      );
      logoText = (
        <Link
          to={logo.innerLink}
          className="simple-text logo-normal"
          // onClick={() => history.push(`/company`)}
          >
          {logo.text}
        </Link>
      );
    }
  }
  return (
        <div className="sidebar" data="green">
          <div className="sidebar-wrapper" ref={sidebarRef}>
            {logoImg !== null || logoText !== null ? (
              <>
              <div 
              
              // onClick={() => history.push(`/company`)}

              style={{width: 120, 
              // paddingLeft: 20, paddingTop: 20, paddingBottom: 10,
                overflow: 'hidden',margin:'20px',marginLeft:'50px',marginTop:'20px'}}>{logoImg}</div>
                <p style={{textAlign: "center", marginBottom: -12}}>{user.name}</p>
              <div className="logo">
              </div>
              <p style={{textAlign: "center",textTransform:'capitalize',fontWeight:'bold', marginTop: 3}}>{user.role}</p>        
              </>
            ) : null}
            <Nav>
              {routes.map((prop, key) => {
                // if (prop.redirect || !prop.roles || !prop.roles.includes(user.role) || !prop.license.includes(business.license)) return null;
                if (prop.redirect || !prop.roles || !prop.roles.includes(user.role)) return null;
                return (
                  <li
                    className={
                      activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                    }
                    key={key}
                  >
                    <NavLink
                      to={prop.path}
                      as={Link}
                      className="nav-link"
                      // activeClassName="active"
                      onClick={props.toggleSidebar}
                    >
                      <p style={{ fontSize: 12, fontWeight: 500}}>{typeof prop.icon === "string" ?
                        <i className={prop.icon} /> :
                        <i>{prop.icon}</i>
                      }{prop.name}</p>
                    </NavLink>
                  </li>
                );
              })}
               {/* <img
          
          style={{position: 'absolute',
            width: '104px',
            height: '100px',
            left: '50px',
            top: '676px',
            fontFamily: 'Myriad Pro',
            fontStyle: 'normal',
            fontWeightt: '400',
            fontSize: '25px',
            color: '#1D76BB'
            }}
          
          
           src={cocopath} alt="Cocopath Logo" /> */}
              <li className="active-pro">
                <ReactstrapNavLink style={{marginBottom: 25}} href={"https://" + business.website}>
                  {/* <i className="tim-icons icon-spaceship" /> */}
                  <p style={{fontSize: 14}}>{business.abbrev}</p>
                </ReactstrapNavLink>
              </li>
            </Nav>
          </div>
        </div>
  );
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string,
  }),
};

export default Sidebar;
