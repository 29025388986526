import React from "react";
import classNames from "classnames";
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader,
  Row,
  Col
} from "reactstrap";
import { isAuth } from "../../actions/auth";
import { NavLink as ReactstrapNavLink } from "reactstrap";
import PBCSLight from '../../assets/img/pbcs_light.png'
import PBCSDark from '../../assets/img/pbcs_dark.png'
import Avatar from '../../assets/img/default-avatar.png';
import { ThemeContext } from '../../contexts/ThemeContext';
import { Link } from "react-router-dom";
import NavLink  from 'react-bootstrap/Nav';


const styles = {

  SDP_Logo:{
    width: '380px',
    height: '30px',
    float:'right',
    // fontFamily: 'Myriad Pro',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '25px',
    color: '#1D76BB',
    marginRight:'5px',
    marginBottom: '6px'
  }

}

function SDPNavbar(props) {
  const user = isAuth();
  const [collapseOpen, setcollapseOpen] = React.useState(false);
  const [modalSearch, setmodalSearch] = React.useState(false);
  const [color, setcolor] = React.useState("navbar-transparent");
  const theme = React.useContext(ThemeContext);
  const isDark = theme.theme === "" ? true : false;
  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setcolor("bg-white");
    } else {
      setcolor("navbar-transparent");
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setcolor("navbar-transparent");
    } else {
      setcolor("bg-white");
    }
    setcollapseOpen(!collapseOpen);
  };
  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setmodalSearch(!modalSearch);
  };
  return (
    <>
      <Navbar className={classNames("navbar-absolute", color)} expand="lg" style={{width:'100%'}}>
        <Container fluid>
           <div className="navbar-wrapper" style={{width:'100%'}}>
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened,
              })}
            >
              <NavbarToggler onClick={props.toggleSidebar}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </NavbarToggler>
            </div>
             <NavbarBrand className="new_style_path" style={{marginLeft:'0px',marginTop:'0px', fontSize: "20px", color: isDark ? "white" : "black"}}>
              {(props.brandText !== "") ? props.brandText : 'User Profile' }
            </NavbarBrand>
          </div>
          <a style={styles.SDP_Logo} href="https://www.clinicalsquared.com">
            <img
          
          
          
          src={isDark ? PBCSDark : PBCSLight} alt="SDP" />
          </a>
          <NavbarToggler onClick={toggleCollapse}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Row>
            <Col>
              <Collapse navbar isOpen={collapseOpen}>
                <Nav className="ml-auto" navbar>
                  <UncontrolledDropdown nav >
                  {/* <InputGroup className="search-bar">
                <Button color="link" onClick={toggleModalSearch}>
                  <i className="tim-icons icon-zoom-split" />
                  <span className="d-lg-none d-md-block">Search</span>
                </Button>
              </InputGroup>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  nav
                >
                  <div className="notification d-none d-lg-block d-xl-block" />
                  <i className="tim-icons icon-sound-wave" />
                  <p className="d-lg-none">Notifications</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">
                      Mike John responded to your email
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">
                      You have 5 more tasks
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">
                      Your friend Michael is in town
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">
                      Another notification
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">
                      Another one
                    </DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown> */}
                    <Col style={{width:'80px'}}>
                      {/* <NavbarText style={{ marginTop: 10, float: "left", marginRight: 22, fontSize: 16 }}><p>{user.name}</p></NavbarText> */}
                      <DropdownToggle
                        caret
                        color="default"
                        nav
                        onClick={(e) => e.preventDefault()}
                      >
                        <div className="photo">
                          <img
                            alt="..."
                            src={user.profile_picture || Avatar}
                          />
                        </div>
                        <b className="caret d-none d-lg-block d-xl-block" />
                        {/* <p className="d-lg-none">Log out</p> */}
                      </DropdownToggle>

                      <DropdownMenu className="dropdown-navbar" right tag="ul">
                        <NavLink tag="li" to={`/user/${user.id}`} as={Link}>
                          <DropdownItem className="nav-item">Profile:&nbsp;&nbsp;{`${user.first_name}`}</DropdownItem>
                        </NavLink>
                        <ReactstrapNavLink divider="true" tag="li" />
                        <ReactstrapNavLink tag="li">
                          <DropdownItem href='/logout' className="nav-item">Log out</DropdownItem>
                        </ReactstrapNavLink>
                      </DropdownMenu>
                    </Col>
                  </UncontrolledDropdown>
                  <li className="separator d-lg-none" />
                </Nav>
              </Collapse>
            </Col>
          </Row>
        </Container>
      </Navbar>
      <Modal
        modalClassName="modal-search"
        isOpen={modalSearch}
        toggle={toggleModalSearch}
      >
        <ModalHeader>
          <Input placeholder="SEARCH" type="text" />
          <button
            aria-label="Close"
            className="close"
            onClick={toggleModalSearch}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </ModalHeader>
      </Modal>
    </>
  );
}

export default SDPNavbar;
