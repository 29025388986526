import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { isAuth, isUserAdmin, isUserSuperadmin } from '../actions/auth';
import UserListItem from './includes/UserListItem';
import CoreSpinner from '../components/_core/CoreSpinner';
import {
  Card,
  CardBody,
  Table,
  Row,
  Col,
} from "reactstrap";
import { getUsers as listUsers } from '../actions/helpers';
import AddOutlinedIcon from '@mui/icons-material/Add';
import { get, post, put, deleteItem } from '../actions/api';


const styles = {
  Container: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    marginLeft: '280px',
    marginRight: '60px',
    marginTop: '80px',
    marginBottom: '130px'
  },
  Button: {
    marginBottom: 15,
    background: 'rgba(108, 133, 215)',
    marginTop: '20px',
    paddingLeft: 32,
    left: '20px',
    fontSize: '15px',
    fontWeight: 'bold'
  }
}

function Users() {
  const history = useHistory();
  const [users, setUsers] = useState(null);
  const [nUsers, setNUsers] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!users) {
      getUsers();
    }
  });

  async function toggleUsers() {
    setLoading(true);
    await post("/businesses/toggle?business_id=" + isAuth().business.id);
    window.location.reload();
  };

  const getUsers = async () => {
    const allUsers = await listUsers(isUserSuperadmin() && isAuth().business.id);
    if (!allUsers) {
      setUsers([]);
    } else {
      setUsers(allUsers);
      const count = allUsers.filter(({ role }) => role === 'user').length;
      setNUsers(count);
    };
  };

  return (
    <>
      <CoreSpinner loading={!users || loading} />
      <div className="content1" style={styles.Container}>
        { isUserAdmin() ?
        <>
        {nUsers <= 5 &&
          <button className="btn btn-primary" style={styles.Button} onClick={() => history.push("/user/create")}><AddOutlinedIcon style={{ marginBottom: 2, fontSize: 22 }} />&nbsp;&nbsp;Create User</button>
        }
        </>
        :
        <>
        { isUserSuperadmin() &&
        <button className="btn btn-primary" style={styles.Button} onClick={() => history.push("/user/create")}><AddOutlinedIcon style={{ marginBottom: 2, fontSize: 22 }} />&nbsp;&nbsp;Create User</button>
        }
        </>
        }
        { isUserSuperadmin() && isAuth().business.id !== 2 && users && users.length > 0 ?
            <button className={'btn btn-danger'} style={{ margin: "5px 0px 0px 45px", padding: "14px 20px 14px 20px" }} onClick={toggleUsers}>Disable All Users</button>
          :
          null
        }
        <Row>
          <Col md={"11"}>
            <Card>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th style={{ fontWeight: 'bold' }}>Name</th>
                      <th style={{ fontWeight: 'bold' }}>Title</th>
                      <th style={{ fontWeight: 'bold' }}>Username</th>
                      {/* <th style={{ textAlign: "center", fontWeight: 'bold' }}>Status</th> */}
                      <th style={{ textAlign: "center", fontWeight: 'bold' }}>Enabled</th>
                      <th style={{ textAlign: "center", fontWeight: 'bold' }}>Role</th>
                      {/* <th style={{ textAlign: "center" , fontWeight:'bold', color: '#000000'}}>Last Login at</th> */}
                    </tr>
                  </thead>
                  {users && users.length < 1 ?
                    <p style={{ padding: 10 }}>No Users</p>
                    :
                    null
                  }
                  {nUsers >= 5 ?
                    <p style={{ padding: 10, color: "orange" }}>Max user limit reached (5)</p>
                    :
                    null
                  }
                  {users &&
                    <tbody>
                      {users.map(user => <UserListItem user={user} key={user.id} />)}
                    </tbody>
                  }
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Users;
